import React, { useEffect, useState } from 'react'
import { getAllVibeCards } from '../../services/eventService'
import Header from '../common/header/Header'
import { LoadingIndicator } from '../common/loading'
import { VibeCard } from './card/VibeCard'

const AllVibes = () => {
  const [vibeCard, setVibeCard] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchAllVibes = async () => {
      setLoading(true)
      try {
        const response = await getAllVibeCards()
        if (response.success) {
          setVibeCard(response.data.vibeCard || [])
        } else {
          setVibeCard([])
        }
      } catch (error) {
        console.error('Error fetching all the vibes: ', error)
        setVibeCard([])
      } finally {
        setLoading(false)
      }
    }
    fetchAllVibes()
  }, [])

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <div id="allvibes">
      <Header title="All Vibes" showLink={true} />
      <main>
        <div>New People, New Vibes</div>
        <div id="CreateVibeButton">
          <button>Create Vibe</button>
        </div>
        <div id="BookMarkVibebutton">
          <button>Bookmarked Vibes</button>
        </div>
        <div id="VibeCardContainer">
          {vibeCard.length > 0 ? (
            <div id="Each Card">
              {vibeCard.map((vibeCard, index) => (
                <VibeCard key={index} vibeCard={vibeCard} showVibeDescription={true} showVibeTitle={true} />
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-500">
              <p>No vibes found</p>
            </div>
          )}
        </div>
      </main>
    </div>
  )
}

export default AllVibes
