import { toast, Bounce } from 'react-toastify'

export const showToast = (message, type) => {
  toast[type](message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    transition: Bounce
  })
}

export const showToastWithAction = (title, content, onView) => {
  toast(
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>{title}</div>
      <div style={{ fontSize: '0.8rem', color: '#555' }}>{content}</div>
      <button
        style={{
          alignSelf: 'flex-start',
          padding: '0.3rem 0.6rem',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          fontSize: '0.8rem',
          cursor: 'pointer'
        }}
        onClick={onView}
      >
        View
      </button>
    </div>,
    {
      position: 'top-right',
      autoClose: 5000
    }
  )
}
