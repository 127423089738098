import React, { useEffect, useState } from 'react'
import { sendPrivateEventInvitation } from '../../../services/eventService'
import { getFollowingsOfUser } from '../../../services/userService'
import { showToast } from '../../../services/toastService'

function SendInvitationModal({ showModal, setShowModal, eventDetails }) {
  const _userInfo = localStorage.getItem('user')
  const userInfo = _userInfo ? JSON.parse(_userInfo) : null

  const [loading, setLoading] = useState(false)
  const [friends, setFriends] = useState([])

  const fetchFriendsOfUser = async () => {
    if (!userInfo) return
    setLoading(true)
    const response = await getFollowingsOfUser(userInfo.userId)
    // console.log('🚀 ~ fetchFriendsOfUser ~ response:', response)
    if (response.success) {
      setFriends(response.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    showModal && fetchFriendsOfUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const handleCloseModal = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]')
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false
    })
    setShowModal(false)
  }

  const handleSelectAll = (e) => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]')
    checkboxes.forEach((checkbox) => {
      checkbox.checked = e.target.checked
    })
  }

  const handleSendInvitation = async () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]')
    const selectedFriends = []
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        selectedFriends.push(checkbox.value)
      }
    })

    if (selectedFriends.length === 0) {
      showToast('Please select friends to invite', 'error')
      return
    }

    try {
      await Promise.all(
        selectedFriends.map(async (friendId) => {
          if (friendId === 'all') {
            return
          } else {
            const prompterId = userInfo.userId
            const userId = friendId
            const eventId = eventDetails?.event_id
            const email = friends.find((friend) => parseInt(friend?.User?.user_id) === parseInt(friendId))?.User?.email
            const eventSlug = eventDetails?.slug
            const response = await sendPrivateEventInvitation(prompterId, userId, email, eventId, eventSlug)
            console.log('🚀 ~ handleSendInvitation ~ response:', response)
          }
        })
      )
      showToast('Invitations sent successfully', 'success')
      handleCloseModal()
    } catch (error) {
      console.error('Error sending invitations:', error)
      handleCloseModal()
    }
  }

  return (
    <div
      className={`modal fade ${showModal ? 'show' : ''}`}
      style={{ display: showModal ? 'block' : 'none' }}
      id="send-invitation-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="sendInvitationModalLabel"
      aria-hidden={!showModal}
      data-bs-backdrop="static"
    >
      <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <h3 className="text-base font-semibold text-gray-900" id="modal-title">
                      Send Invitation
                    </h3>
                    <div className="mt-5">
                      <p className="text-sm text-gray-800 text-center">
                        Please select friends to invite to this event.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 max-h-96 overflow-y-auto">
                {friends.length > 0 &&
                  friends.map((friend, idx) => (
                    <div key={idx}>
                      {idx === 0 && (
                        <div key={'select-all'} className="flex items-center justify-between py-2">
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              className="form-checkbox h-5 w-5 text-blue-600 mr-3"
                              id="select-all"
                              name="select-all"
                              onChange={handleSelectAll}
                              value={'all'}
                            />
                            <label htmlFor="select-all" className="block text-sm font-medium text-gray-700">
                              Select All
                            </label>
                          </div>
                        </div>
                      )}
                      <div className="flex items-center justify-between py-2">
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-blue-600 mr-3"
                            id={friend.id}
                            name={friend.id}
                            value={friend?.User?.user_id}
                          />
                          <img
                            src={friend?.User?.Profile?.profileImgUrl || '/assets/images/default_pfp.png'}
                            alt={friend?.User?.Profile?.username}
                            className="h-8 w-8 rounded-full"
                          />
                          <label htmlFor={friend.id} className="ml-3 block text-sm font-medium text-gray-700">
                            {friend?.User?.Profile?.username || 'N/A'}
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {loading && (
                <div className="bg-gray-50 px-4 py-3">
                  <div className="flex items-center justify-center">
                    <div className="w-6 h-6 border-2 border-t-[4px] border-gray-900 rounded-full animate-spin"></div>
                  </div>
                </div>
              )}
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                  onClick={handleSendInvitation}
                >
                  Send
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && <div className="modal-backdrop fade show z-n1"></div>}
    </div>
  )
}

export default SendInvitationModal
