import React from 'react'
import { Link } from 'react-router-dom'

export const VibeCard = ({ user_id, username, profile_picture_url, about_me }) => {
  if (!user_id) return null

  // const _userInfo = localStorage.getItem('user')
  // const userInfo = _userInfo ? JSON.parse(_userInfo) : null

  return (
    <div>
      <Link to={`/promoter/profile/${user_id}`} className="text-decoration-none">
        <div
          id="vibeCard"
          className="p-6 mb-3 mt-3 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4"
        >
          <div className="flex-shrink-0">
            <img
              className="h-12 w-12 rounded-sm"
              alt="Vibe"
              src={profile_picture_url || '/assets/images/default_pfp.png'}
            />
          </div>
          <div id="vibeCard-title" className="text-xl font-medium text-black">
            {username}
          </div>
          <div id="vibeCard-description">
            <p>{about_me}</p>
          </div>
        </div>
      </Link>
    </div>
  )
}
