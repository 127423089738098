import React, { useEffect, useState, useContext } from 'react'
import { getTickets } from '../../services/ticketService'
import Header from '../common/header/Header'
import { addVibeMediaForEvent, uploadImage } from '../../services/eventService'
import { SocketContext } from '../../hooks/SocketContext'
import { useNavigate } from 'react-router-dom'

function VibesMediaUpload() {
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem('user'))
  const [loading, setLoading] = useState(false)
  const [tickets, setTickets] = useState([])
  const [data, setData] = useState({
    event_id: '',
    description: '',
    image: '',
    slug: ''
  })
  const [isImgUploading, setIsImgUploading] = useState(false)
  const [preview, setPreview] = useState('')
  const { socket } = useContext(SocketContext)

  const fetchAllTickets = async () => {
    if (!user || !user?.userId) return
    setLoading(true)
    const response = await getTickets('all', user?.userId)
    if (response && response.tickets && response.tickets.length > 0) {
      const data = response.tickets
      setTickets(data)
    }
    setLoading(false)
  }

  const handleEventChange = (e) => {
    const slug = tickets.find((ticket) => parseInt(ticket.event_id) === parseInt(e.target.value))?.Event?.slug
    setData({ ...data, event_id: e.target.value, slug })
  }

  const handleImageChange = async (e) => {
    setIsImgUploading(true)
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreview(reader.result)
      }
      reader.readAsDataURL(file)
    }
    try {
      const imgFormData = new FormData()
      imgFormData.append('image', file)
      const url = await uploadImage(imgFormData)
      setData({ ...data, image: url.data.imageUrl })
    } catch (error) {
      console.log('🚀 ~ handleImageChange ~ error:', error)
    } finally {
      setIsImgUploading(false)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!data.event_id || !data.image || !user.userId) return
    setLoading(true)
    try {
      data.user_id = user.userId
      const response = await addVibeMediaForEvent(data)
      setLoading(false)
      if (response.success) {
        socket.emit('add_vibe_media', data)
        navigate(`/promoter/vibes/${user.userId}`)
      }
    } catch (error) {
      setLoading(false)
      console.log('🚀 ~ handleSubmit ~ error:', error)
    }
  }

  useEffect(() => {
    fetchAllTickets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id="vibes-media-upload">
      <Header title="Vibes" showLink={true} />
      <main className="p-4">
        <form>
          <div className="my-6">
            <label htmlFor="ticket_select" className="block mb-2 text-sm font-medium text-gray-900">
              Event List
            </label>
            <select
              id="ticket_select"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              onChange={(e) => handleEventChange(e)}
              required
            >
              <option selected>Choose a event</option>
              {tickets.length > 0 &&
                tickets.map((ticket) => (
                  <option key={ticket.event_id} value={ticket.event_id}>
                    {ticket?.Event?.name || ticket?.Event?.slug}
                  </option>
                ))}
            </select>
          </div>
          {(loading || isImgUploading) && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="w-12 h-12 border-4 border-t-[6px] border-white rounded-full animate-spin"></div>
            </div>
          )}

          {data.event_id && (
            <div className="my-3">
              <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900">
                Description
              </label>
              <textarea
                id="description"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder=""
                rows={5}
                value={data.description}
                onChange={(e) => setData({ ...data, description: e.target.value })}
                required
              />
            </div>
          )}

          {data.event_id && (
            <div className="my-3">
              <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900">
                Image
              </label>
              <div
                className="flex items-center justify-between"
                onClick={() => document.getElementById('image').click()}
                style={{ cursor: 'pointer' }}
                disabled={isImgUploading}
              >
                {preview ? (
                  <img src={preview} alt="preview" className="w-full h-36 object-cover rounded-lg" />
                ) : (
                  <div
                    className="bg-gray-50 w-full h-36 flex items-center justify-center text-gray-900 text-sm font-medium rounded-lg"
                    onClick={(e) => {
                      e.stopPropagation()
                      document.getElementById('image').click()
                    }}
                  >
                    Upload Image
                  </div>
                )}
                <input id="image" type="file" className="hidden" accept="image/*" onChange={handleImageChange} />
              </div>
            </div>
          )}

          <button
            type="submit"
            onClick={handleSubmit}
            disabled={isImgUploading || !data.event_id || !data.image}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-sm w-full px-5 py-2.5 text-center my-5 disabled:opacity-80"
          >
            Submit
          </button>
        </form>
      </main>
    </div>
  )
}

export default VibesMediaUpload
