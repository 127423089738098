const PRODUCTION_SERVER_BASEURL = 'https://api.greatnight.us'
const STAGING_SERVER_BASEURL = 'https://api-dev.greatnight.us'
const DEV_SERVER_BASEURL = 'http://localhost:3000'
const PRODUCTION_API_URL = 'https://api.greatnight.us/v1/api'
const STAGING_API_URL = 'https://api-dev.greatnight.us/v1/api'
const DEV_API_URL = 'http://localhost:3000/v1/api'
const DEV_TEST_FRONTEND_URL = 'http://localhost:4200'
const STAGING_TEST_FRONTEND_URL = 'https://app-dev.greatnight.us'
const PRODUCTION_TEST_FRONTEND_URL = 'https://app.greatnight.us'
const NER_API_URL = 'https://app.greatnight.us'

const buildEnv = process.env.REACT_APP_GREAT_NIGHT_ENV

const BASE_SERVER_URL =
  buildEnv === 'production'
    ? PRODUCTION_SERVER_BASEURL
    : buildEnv === 'staging'
      ? STAGING_SERVER_BASEURL
      : DEV_SERVER_BASEURL
const BASE_API_URL =
  buildEnv === 'production' ? PRODUCTION_API_URL : buildEnv === 'staging' ? STAGING_API_URL : DEV_API_URL
const FRONTEND_URL =
  buildEnv === 'production'
    ? PRODUCTION_TEST_FRONTEND_URL
    : buildEnv === 'staging'
      ? STAGING_TEST_FRONTEND_URL
      : DEV_TEST_FRONTEND_URL

const config = {
  BASE_API_URL,
  BASE_SERVER_URL,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  GOOGLE_AUTH_CLIENT_ID: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
  FRONTEND_URL,
  NER_API_URL,
  REACT_APP_STRIPE_PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
}

export default config
