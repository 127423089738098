import React from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import './Navigation.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faTicketSimple, faChartLine, faCalendarPlus } from '@fortawesome/free-solid-svg-icons'
import useMessageStore from '../../../store/messageStore'
import { useUser } from '../../../hooks/UserContext'

function Navigation() {
  const location = useLocation()
  const navigate = useNavigate()
  let returnNavigation = true
  const exemptRoutes = ['login', 'register', 'forgot-password', 'terms-of-service', 'privacy-policy']
  const numOfNotifications = useMessageStore((state) => state.numOfNotifications)
  const { hasActiveSubscription } = useUser()

  const handleCreateEventClick = (e) => {
    // If user doesn't have an active subscription, redirect to subscription page
    if (!hasActiveSubscription) {
      e.preventDefault()
      navigate('/subscription/plans')
    }
  }

  if (location.pathname === '/') {
    return true
  }

  exemptRoutes.map((route) => {
    if (location.pathname.endsWith(route)) {
      returnNavigation = false
    }
    return true
  })

  if (!returnNavigation) {
    return null
  }

  return (
    <div className="flex justify-between text-center p-4 border-t border-gray-100" id="nav">
      <NavLink end to="/promoter" className="col btn" activeclassname="active">
        <div className="flex justify-center text-center">
          <FontAwesomeIcon icon={faHouse} />
        </div>
        <div className="inter-700 font-normal">Home</div>
      </NavLink>

      <NavLink end to="/promoter/my-event" className="col btn" activeclassname="active">
        <div className="flex justify-center text-center">
          <div className="relative inline-flex">
            <FontAwesomeIcon icon={faTicketSimple} />
            {numOfNotifications > 0 && (
              <span className="absolute top-0 right-0.5 grid min-h-[24px] min-w-[24px] translate-x-2/4 -translate-y-2/4 place-items-center rounded-full bg-red-600 py-1 px-1 text-xs text-white">
                {numOfNotifications}
              </span>
            )}
          </div>
        </div>
        <div className="relative inter-700 font-normal">
          <span>Attending</span>
        </div>
      </NavLink>

      <NavLink
        end
        to="/promoter/my-event/create"
        className="col btn"
        activeclassname="active"
        onClick={handleCreateEventClick}
      >
        <div className="flex justify-center text-center">
          <FontAwesomeIcon icon={faCalendarPlus} />
        </div>
        <div className="inter-700 font-normal">Create</div>
      </NavLink>

      <NavLink end to="/dashboard" className="col btn" activeclassname="active">
        <div className="flex justify-center text-center">
          <FontAwesomeIcon icon={faChartLine} />
        </div>
        <div className="inter-700 font-normal">Dashboard</div>
      </NavLink>
    </div>
  )
}

export default Navigation
