import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Map from '../../common/map/Map'
import {
  cancelEvent,
  deleteEvent,
  formatEventTime,
  getInvitationById,
  // getVibesMediasForEvent,
  processInvitation
} from '../../../services/eventService'
import { joinToChatRoom } from '../../../services/chatService'
import { LoadingIndicator } from '../../common/loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare, faTicket, faArrowLeft, faShareNodes } from '@fortawesome/free-solid-svg-icons'
import { faBell, faClock, faBuilding } from '@fortawesome/free-regular-svg-icons'
import SendInvitationModal from './SendInvitationModal'
import EventDashboardModal from './EventDashboardModal'
import { showToast } from '../../../services/toastService'
import PropTypes from 'prop-types'

function EventDetails({
  eventDetails,
  loading,
  user,
  handleRsvpForRegisteredUser,
  handleNotAttending,
  handleShareEvent,
  handleSaveVibe,
  handleUnsaveVibe,
  setReminderHandler,
  savedEvent
}) {
  // const invite = JSON.parse(localStorage.getItem('invite'))
  // const registerLink = `ticket/register`

  const isRsvpActive = () => {
    const storedRsvp = localStorage.getItem(`event_rsvp_${eventDetails?.event_id}`)
    if (!storedRsvp) return false

    const rsvpData = JSON.parse(storedRsvp)
    const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000
    const isExpired = new Date().getTime() - rsvpData.timestamp > thirtyDaysInMs

    if (isExpired) {
      localStorage.removeItem(`event_rsvp_${eventDetails?.event_id}`)
      return false
    }

    return rsvpData.status
  }

  const [rsvp, setRsvp] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { inviteId, recipientId } = useParams()
  const address = `${eventDetails?.street}, ${eventDetails?.city}, ${eventDetails?.state}, ${eventDetails?.country}`
  // const inviteData = localStorage.getItem('invite')
  // const influencerId = inviteData ? JSON.parse(inviteData).influencerId : null

  const fromEventsList = location.state?.fromEventsList
  const eventIdFromList = location.state?.eventId

  const handleBackNavigation = () => {
    if (fromEventsList) {
      // Navigate back to events list (homepage) with state to scroll to the event
      navigate('/', {
        state: { scrollToEventId: eventIdFromList }
      })
    } else {
      // Regular back navigation if not coming from events list
      navigate('/')
    }
  }

  const checkAuth = () => {
    if (!user) {
      localStorage.setItem('redirect', window.location.pathname)
      navigate('/login')
      return false
    }
    return true
  }

  // const [vibeMedias, setVibeMedias] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showEDModal, setShowEDModal] = useState(false)
  const [status, setStatus] = useState('')
  const [disabled, setDisabled] = useState(false)

  // const getVibesMedias = async () => {
  //   const eventId = eventDetails?.event_id
  //   if (eventId) {
  //     const response = await getVibesMediasForEvent(eventId)
  //     if (response.success) {
  //       setVibeMedias(response.media)
  //     }
  //   }
  // }

  const deleteEventHandler = async () => {
    try {
      const data = {
        eventId: eventDetails?.event_id,
        promoterId: JSON.parse(localStorage.getItem('user'))?.userId
      }
      const response = await deleteEvent(data)
      console.log('deleteEventHandler response:', response)
      navigate('/promoter/')
    } catch (error) {
      console.error('Error deleting event:', error)
      showToast('Failed to delete event', 'error')
    }
  }

  // eslint-disable-next-line
  const cancelEventHandler = async () => {
    const response = await cancelEvent(eventDetails?.event_id)
    console.log('cancelEventHandler response:', response)
    navigate('/promoter/my-event')
  }

  const handleJoinChat = async () => {
    const payload = {
      eventId: eventDetails?.event_id,
      userId: JSON.parse(localStorage.getItem('user'))?.userId
    }
    const res = await joinToChatRoom(payload)
    if (res.success) {
      navigate(`/chatroom/${res.roomId}`, {
        state: {
          eventId: eventDetails?.event_id,
          eventName: eventDetails?.name
        }
      })
    }
    console.log('handleJoinChat payload:', res, payload)
  }

  const handleInvitation = async (status) => {
    const data = {
      inviteId,
      status
    }
    const response = await processInvitation(data)
    showToast(response.message, status === 'Y' ? 'success' : 'error')
    setStatus(status)
  }

  const getInvitationStatus = async (inviteId) => {
    const reponse = await getInvitationById(inviteId)
    if (reponse && reponse.invitation) {
      setStatus(reponse.invitation.status)
    }
  }

  useEffect(() => {
    const storedRsvp = localStorage.getItem(`event_rsvp_${eventDetails?.event_id}`)
    if (storedRsvp) {
      const rsvpData = JSON.parse(storedRsvp)
      const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000
      const isExpired = new Date().getTime() - rsvpData.timestamp > thirtyDaysInMs

      if (!isExpired) {
        setRsvp(rsvpData.status)
      } else {
        localStorage.removeItem(`event_rsvp_${eventDetails?.event_id}`)
      }
    }
  }, [eventDetails?.event_id])

  useEffect(() => {
    const remindersArray = JSON.parse(localStorage.getItem('reminders')) || []
    inviteId && getInvitationStatus(inviteId)

    if (remindersArray.length > 0) {
      const reminderExists = remindersArray.some((reminder) => reminder.eventId === eventDetails?.event_id)
      if (reminderExists) {
        setDisabled(true)
      }
    }
  }, [inviteId, eventDetails?.event_id])

  // useEffect(() => {
  //   getVibesMedias()
  //   // eslint-disable-next-line
  // }, [eventDetails])

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <>
      <Helmet>
        <title>{eventDetails?.name || 'Event Details'}</title>
        <meta name="description" content={eventDetails?.description?.replace(/<[^>]*>/g, '') || 'Event details'} />
        <meta property="og:title" content={eventDetails?.name || 'Event Details'} />
        <meta
          property="og:description"
          content={eventDetails?.description?.replace(/<[^>]*>/g, '') || 'Event details'}
        />
        {eventDetails?.flyer && <meta property="og:image" content={eventDetails.flyer} />}
        <meta property="og:type" content="event" />
        <meta property="event:start_time" content={eventDetails?.start} />
        <meta property="event:end_time" content={eventDetails?.end} />
        <meta property="event:location" content={address} />
        <link rel="canonical" href={`https://greatnight.us/events/${eventDetails?.event_id}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@greatnightapp" />
        <meta name="twitter:title" content={eventDetails?.name} />
        <meta name="twitter:description" content={eventDetails?.description?.replace(/<[^>]*>/g, '')} />
        {eventDetails?.flyer && <meta name="twitter:image" content={eventDetails.flyer} />}
      </Helmet>

      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Event',
          name: eventDetails?.name,
          description: eventDetails?.description?.replace(/<[^>]*>/g, ''),
          startDate: eventDetails?.start,
          endDate: eventDetails?.end,
          location: {
            '@type': 'Place',
            name: eventDetails?.venue,
            address: {
              '@type': 'PostalAddress',
              streetAddress: eventDetails?.street,
              addressLocality: eventDetails?.city,
              addressRegion: eventDetails?.state,
              addressCountry: eventDetails?.country
            }
          },
          image: eventDetails?.flyer,
          offers: eventDetails?.ticket_link
            ? {
                '@type': 'Offer',
                url: eventDetails.ticket_link
              }
            : undefined
        })}
      </script>

      <main className="w-full max-w-[600px] mx-auto m-2">
        <article>
          <header className="relative" id="event-img">
            {eventDetails?.flyer && (
              <>
                <div className="absolute top-4 left-4 z-10">
                  <button
                    onClick={handleBackNavigation}
                    className="bg-black bg-opacity-50 hover:bg-opacity-70 text-white m-3 p-3 rounded-full transition-all duration-200"
                    type="button"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} size="lg" />
                  </button>
                </div>

                {/* Share button on the right side */}
                <div className="absolute top-4 right-4 z-10">
                  <button
                    onClick={handleShareEvent}
                    className="bg-black bg-opacity-50 hover:bg-opacity-70 text-white m-3 p-3 rounded-full transition-all duration-200"
                  >
                    <FontAwesomeIcon icon={faShareNodes} size="xl" />
                  </button>
                </div>

                <img
                  src={eventDetails?.flyer}
                  className="img-fluid w-full max-w-[600px]"
                  alt={`${eventDetails?.name} Event Flyer`}
                  loading="lazy"
                  width="600"
                  height="auto"
                />
              </>
            )}
            <h1 className="text-[#060a0f] text-xl font-medium font-roboto leading-7 ml-2 mt-4">
              {eventDetails?.name || 'N/A'}
            </h1>

            <div className="event-metadata">
              <time className="text-[#515151] text-sm font-medium font-arsenal ml-2" dateTime={eventDetails?.start}>
                <FontAwesomeIcon icon={faClock} /> &nbsp;
                {formatEventTime(eventDetails?.start, eventDetails?.end)}
              </time>

              <address className="text-[#515151] text-sm font-medium font-arsenal ml-2 not-italic">
                <FontAwesomeIcon icon={faBuilding} />
                &nbsp;
                {eventDetails?.venue} {eventDetails?.street} {eventDetails?.city}
              </address>
            </div>
          </header>

          <section className="event-description">
            <p
              className="text-[#515151] mb-1 font-roboto text-sm leading-tight ml-2"
              dangerouslySetInnerHTML={{ __html: eventDetails?.description }}
            ></p>
          </section>

          {eventDetails?.ticket_link && (
            <>
              <br />
              <a
                className="text-[#515151] text-sm font-medium font-arsenal ml-2 cursor-pointer"
                href={eventDetails?.ticket_link}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTicket} />
                &nbsp;
                {eventDetails?.ticket_link}
              </a>
            </>
          )}
          <div className="flex flex-nowrap">
            <button
              className={`w-[120px] h-[15px] m-3 p-3 bg-[#5250ea] rounded-[80px] justify-center items-center gap-2 text-sm text-white inline-flex ${disabled ? 'bg-neutral-300' : 'bg-neutral-800'}`}
              onClick={setReminderHandler}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faBell} />
              <span>Remind Me</span>
            </button>
            <button
              className={`w-[120px] h-[15px] m-3 p-3 rounded-[80px] justify-center items-center gap-2 text-sm text-white inline-flex
                ${isRsvpActive() ? 'bg-gray-500' : 'bg-[#5250ea]'}`}
              id="book-event"
              onClick={() => {
                if (checkAuth()) {
                  if (rsvp) {
                    handleNotAttending()
                  } else {
                    handleRsvpForRegisteredUser()
                  }
                  setRsvp(!rsvp)
                  localStorage.setItem(
                    `event_rsvp_${eventDetails?.event_id}`,
                    JSON.stringify({
                      status: !rsvp,
                      timestamp: new Date().getTime()
                    })
                  )
                }
              }}
            >
              <FontAwesomeIcon icon={faTicket} /> {rsvp ? 'Attending' : `I'm Going`}
            </button>
            <Link
              onClick={handleShareEvent}
              className="w-[90px] h-[15px] m-3 p-3 bg-[#5250ea] rounded-[80px] justify-center items-center gap-2 text-sm text-white inline-flex"
              id="share-event"
            >
              <FontAwesomeIcon icon={faShare} /> Share
            </Link>
          </div>

          <section id="location" className="mb-8 text-neutral-600">
            <div id="map" className="w-full mt-4">
              <Map address={address} />
            </div>
            <div className="flex justify-center items-center gap-6 my-4">
              <div
                onClick={!savedEvent ? handleSaveVibe : handleUnsaveVibe}
                id="book-event"
                className="mt-4 w-[160px] h-[35px] text-decoration-none button button--secondary text-center text-white block"
                style={{ pointerEvents: 'auto', opacity: 1 }}
              >
                {savedEvent ? 'Unsave' : 'Save To My Vibes'}
              </div>
            </div>
          </section>

          {status === 'NR' && inviteId && recipientId && user?.userId !== eventDetails?.user_id && (
            <div className="text-center flex items-center justify-center mb-4 gap-4">
              <div className="w-1/2">
                <Link
                  onClick={() => handleInvitation('Y')}
                  className="text-decoration-none button button--primary text-white inline-flex text-center"
                  id="send-invitation"
                >
                  Accept
                </Link>
              </div>
              <div className="w-1/2">
                <Link
                  onClick={() => handleInvitation('R')}
                  id="private-event-dashboard"
                  className="text-decoration-none button button--tertiary inline-flex text-center text-white"
                >
                  Reject
                </Link>
              </div>
            </div>
          )}

          {/* Hide Send Invitation Buttons
            {user?.userId === eventDetails?.user_id && (
              <div className="text-center flex items-center justify-center mb-4 gap-4">
                <div className="w-1/2">
                  <Link
                    onClick={() => setShowModal(true)}
                    className="text-decoration-none button button--secondary text-white block text-center"
                    id="send-invitation"
                  >
                    Send Invitation
                  </Link>
                </div>
                <div className="w-1/2">
                  <Link
                    onClick={() => setShowEDModal(true)}
                    id="private-event-dashboard"
                    className="text-decoration-none button button--secondary block text-center text-white"
                  >
                    Private Event Dashboard
                  </Link>
                </div>
              </div>
            )} */}

          <div className="flex justify-center items-center gap-6 my-4">
            <div className="flex justify-center items-center ">
              {user?.userId === eventDetails?.user_id && (
                <Link
                  to={`/promoter/event/edit/${eventDetails?.event_id}`}
                  id="book-event"
                  className="w-[100px] h-[15px] m-3 p-3 bg-[#5250ea] rounded-[80px] justify-center items-center gap-2 text-sm text-white inline-flex"
                >
                  Edit
                </Link>
              )}
              {(user?.userId === eventDetails?.user_id || user?.role === 6) && (
                <Link
                  className="w-[100px] h-[15px] m-3 p-3 bg-[#FF0000] rounded-[80px] justify-center items-center gap-2 text-sm text-white inline-flex"
                  onClick={deleteEventHandler}
                >
                  Delete
                </Link>
              )}
            </div>

            {user?.userId && user?.userId === eventDetails?.user_id && (
              <>
                <SendInvitationModal showModal={showModal} setShowModal={setShowModal} eventDetails={eventDetails} />
                <EventDashboardModal
                  showEDModal={showEDModal}
                  setShowEDModal={setShowEDModal}
                  eventDetails={eventDetails}
                  handleJoinChat={handleJoinChat}
                />
              </>
            )}
          </div>
          {/* Displays Vibe photos that user shared underneath eventDetails */}
          {/* {vibeMedias.length > 0 &&
            vibeMedias.map((media, index) => (
              <div className="my-6 event relative max-w-[720px] mx-auto" key={`media-${index}`}>
                {media.image && (
                  <div className="relative mb-4">
                    <div className="text-sm font-bold ml-2 mb-2">
                      {media.description || ''} <br />{' '}
                    </div>
                    <div className="z-30 card-img-top rounded-3 relative">
                      <img src={media.image} alt={media.description || 'Event'} />
                    </div>
                  </div>
                )}
              </div>
            ))} */}
        </article>
      </main>
    </>
  )
}

EventDetails.propTypes = {
  eventDetails: PropTypes.shape({
    event_id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    venue: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    flyer: PropTypes.string,
    ticket_link: PropTypes.string,
    user_id: PropTypes.string
  }),
  loading: PropTypes.bool,
  user: PropTypes.object,
  handleRsvpForRegisteredUser: PropTypes.func.isRequired,
  handleNotAttending: PropTypes.func.isRequired,
  handleShareEvent: PropTypes.func.isRequired,
  handleSaveVibe: PropTypes.func.isRequired,
  handleUnsaveVibe: PropTypes.func.isRequired,
  setReminderHandler: PropTypes.func.isRequired,
  savedEvent: PropTypes.bool
}

export default EventDetails
