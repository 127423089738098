import React, { useEffect } from 'react'
import './TicketScan.css'
import { qrScanDataForRsvp } from '../../../services/eventService'
import { showToast } from '../../../services/toastService'

function TicketScan({ user, rsvpId, influencerId }) {
  console.log('🚀 ~ TicketScan ~', { user, rsvpId, influencerId })

  useEffect(() => {
    const submitQRScanData = async () => {
      try {
        const response = await qrScanDataForRsvp(rsvpId, influencerId, user.userId)
        console.log('🚀 ~ submitQRScanData ~ response:', response)
        showToast(response.data.message, 'success')
      } catch (error) {
        console.error('Error scanning QR code:', error)
        showToast('Failed to submit QR code data.', 'error')
      }
    }

    if (!user) {
      showToast('You must sign in to GreatNight in order to scan this code.', 'error')
    } else {
      submitQRScanData()
    }
  }, [influencerId, rsvpId, user])

  return (
    <div id="ticket-scan" className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <header>
            <div className="row mt-5 mb-4">
              {!user && (
                <div id="success-msg">
                  <h2 className="mt-5 mb-5 text-center epilogue-400">Unauthorized</h2>
                  <h3 className="mb-3 text-center epilogue-400">
                    You must sign in to GreatNight in order to scan this code.{' '}
                    <a href="/login">Click this link to Sign in.</a>
                  </h3>
                </div>
              )}
            </div>
          </header>

          {user && (
            <main className="ps-5 pe-5 text-center">
              <h2 className="mt-5 mb-5 inter-700">Event QR Scan Details</h2>
              <p>You have successfully scanned the code and recieved 5 points!</p>
            </main>
          )}
        </div>
      </div>
    </div>
  )
}

export default TicketScan
