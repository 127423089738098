import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Elements, CardElement, useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { toast } from 'react-toastify'
import { getSubscriptionPlans, createCheckoutSession } from '../../services/subscriptionService'
import { useUser } from '../../hooks/UserContext'
import { STRIPE_PUBLISHABLE_KEY, SUBSCRIPTION_PLANS, PAYMENT_METHODS, COLORS } from '../../config/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faLock } from '@fortawesome/free-solid-svg-icons'
import { faGooglePay, faPaypal } from '@fortawesome/free-brands-svg-icons'
import { LoadingIndicator } from '../../components/common/loading'

// Initialize Stripe with the publishable key and additional options
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY, {
  locale: 'en', // Set default locale
  stripeAccount: process.env.REACT_APP_STRIPE_CONNECT_ID // Optional: For Stripe Connect
})

/**
 * Get plan details based on plan level
 * @param {string|number} planLevel - The subscription plan level
 * @returns {Object} Plan details object
 */
const getPlanDetails = (planLevel) => {
  const level = parseInt(planLevel, 10)
  return level === 1 ? SUBSCRIPTION_PLANS.LEVEL_1 : SUBSCRIPTION_PLANS.LEVEL_2
}

/**
 * Checkout form component for processing subscription payments
 */
function CheckoutForm({ planLevel, priceId, planAmount }) {
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHODS.CREDIT_CARD)
  const [saveCard, setSaveCard] = useState(true)
  const [cardHolder, setCardHolder] = useState('')
  const [canUseGooglePay, setCanUseGooglePay] = useState(false)
  const [paymentRequest, setPaymentRequest] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const { user } = useUser()
  const navigate = useNavigate()
  const location = useLocation()

  // Get plan details
  const planDetails = getPlanDetails(planLevel)

  // Set up Google Pay / Apple Pay payment request
  useEffect(() => {
    if (!user || !stripe || !planAmount) return // Don't proceed if user, stripe, or planAmount is not available

    // Create a PaymentRequest object
    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: `${planDetails.name} Subscription`,
        amount: planAmount
      },
      requestPayerName: true,
      requestPayerEmail: true
    })

    // Check if the Payment Request is available
    pr.canMakePayment().then((result) => {
      if (result) {
        setCanUseGooglePay(true)
        setPaymentRequest(pr)
      }
    })

    // Handle payment method creation
    pr.on('paymentmethod', async (event) => {
      setLoading(true)
      setErrorMessage(null)

      try {
        // Create a checkout session on the server
        const response = await createCheckoutSession(user.userId, planLevel, priceId)

        if (!response.success) {
          throw new Error(response.message || 'Failed to create payment intent')
        }

        // Confirm the card payment with the payment method ID
        const { error, paymentIntent } = await stripe.confirmCardPayment(
          response.clientSecret,
          { payment_method: event.paymentMethod.id },
          { handleActions: false }
        )

        if (error) {
          // Report to customer that payment failed
          event.complete('fail')
          throw error
        }

        event.complete('success')

        if (paymentIntent.status === 'requires_action') {
          // Let Stripe handle the rest of the payment flow
          const { error, paymentIntent: updatedIntent } = await stripe.confirmCardPayment(response.clientSecret)
          if (error) {
            throw error
          }
          handlePaymentSuccess(updatedIntent)
        } else {
          handlePaymentSuccess(paymentIntent)
        }
      } catch (error) {
        console.error('Payment error:', error)
        setErrorMessage(error.message || 'Payment failed. Please try again.')
        toast.error(error.message || 'Payment failed. Please try again.')
        setLoading(false)
      }
    })

    return () => {
      // Clean up event listeners when component unmounts
      pr.off('paymentmethod')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, planLevel, priceId, user, planAmount, planDetails])

  const handlePaymentSuccess = (paymentIntent) => {
    // Get the location state that may have been passed from the subscription guard
    const locationState = location?.state?.from

    // Store redirect information in sessionStorage if we came from a protected route
    if (locationState) {
      sessionStorage.setItem('subscriptionRedirect', JSON.stringify({ from: locationState }))
    }

    toast.success('Subscription successful!')
    // Navigate to the success page
    navigate(`/subscription/success?payment_intent=${paymentIntent.id}`)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements || !user) {
      return
    }

    setLoading(true)
    setErrorMessage(null)

    try {
      // Create a checkout session on the server
      const response = await createCheckoutSession(user.userId, planLevel, priceId)

      if (!response.success) {
        throw new Error(response.message || 'Failed to create payment intent')
      }

      // Handle different payment methods
      if (paymentMethod === PAYMENT_METHODS.CREDIT_CARD) {
        const cardElement = elements.getElement(CardElement)

        if (!cardElement) {
          throw new Error('Card element not found')
        }

        // Confirm card payment with the card element
        const { error: paymentError, paymentIntent } = await stripe.confirmCardPayment(response.clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: cardHolder || user.name,
              email: user.email
            }
          },
          setup_future_usage: saveCard ? 'off_session' : undefined
        })

        if (paymentError) {
          throw paymentError
        }

        handlePaymentSuccess(paymentIntent)
      } else if (paymentMethod === PAYMENT_METHODS.PAYPAL) {
        // Create PayPal payment - open in same window with proper params
        // Use proper state management to track payment process
        const paypalParams = new URLSearchParams({
          client_secret: response.clientSecret,
          customer_id: response.customerId,
          plan_level: planLevel,
          return_url: window.location.origin + '/subscription/success'
        }).toString()

        window.location.href = `/paypal-redirect?${paypalParams}`
      }
    } catch (error) {
      console.error('Payment error:', error)
      setErrorMessage(error.message || 'Payment failed. Please try again.')
      toast.error(error.message || 'Payment failed. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  // Show loading state if user is not yet available
  if (!user) {
    return <LoadingIndicator />
  }

  return (
    <div className="max-w-lg mx-auto py-12">
      <h1 className="text-4xl font-bold mb-12">
        {planDetails.name} - Total ${planDetails.monthlyCost}/month
      </h1>

      <div className="mb-12">
        <h2 className="text-2xl mb-6">Pay with</h2>
        <div className="flex gap-4">
          <button
            type="button"
            className={`flex-1 px-4 py-3 rounded-[2rem] text-md font-medium transition-all duration-200 flex items-center justify-center gap-2 ${
              paymentMethod === PAYMENT_METHODS.CREDIT_CARD ? COLORS.SELECTED : COLORS.UNSELECTED
            }`}
            onClick={() => setPaymentMethod(PAYMENT_METHODS.CREDIT_CARD)}
          >
            <FontAwesomeIcon icon={faCreditCard} className="text-xl" />
            <span>Credit card</span>
          </button>
          <button
            type="button"
            className={`flex-1 px-4 py-3 rounded-[2rem] text-md font-medium transition-all duration-200 flex items-center justify-center gap-2 ${
              paymentMethod === PAYMENT_METHODS.GOOGLE_PAY ? COLORS.SELECTED : COLORS.UNSELECTED
            } ${!canUseGooglePay ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => canUseGooglePay && setPaymentMethod(PAYMENT_METHODS.GOOGLE_PAY)}
            disabled={!canUseGooglePay}
          >
            <FontAwesomeIcon icon={faGooglePay} className="text-xl" />
            <span>Google Pay</span>
          </button>
          <button
            type="button"
            className={`flex-1 px-4 py-3 rounded-[2rem] text-md font-medium transition-all duration-200 flex items-center justify-center gap-2 ${
              paymentMethod === PAYMENT_METHODS.PAYPAL ? COLORS.SELECTED : COLORS.UNSELECTED
            }`}
            onClick={() => setPaymentMethod(PAYMENT_METHODS.PAYPAL)}
          >
            <FontAwesomeIcon icon={faPaypal} className="text-xl" />
            <span>Paypal</span>
          </button>
        </div>
      </div>

      {errorMessage && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-lg text-red-700">
          <p>{errorMessage}</p>
        </div>
      )}

      {paymentMethod === PAYMENT_METHODS.GOOGLE_PAY && paymentRequest ? (
        <div className="space-y-8">
          <div className="bg-gray-50 p-4 rounded-xl">
            <PaymentRequestButtonElement
              options={{
                paymentRequest,
                style: {
                  paymentRequestButton: {
                    theme: 'dark',
                    height: '48px'
                  }
                }
              }}
            />
          </div>

          <div className="flex items-center gap-2 text-gray-700">
            <FontAwesomeIcon icon={faLock} />
            <p className="text-sm">Your payment information is encrypted and secure</p>
          </div>

          <p className="text-base text-gray-600">
            By selecting the button above, I agree to the{' '}
            <a href="/terms-of-service" className="text-gray-900 underline font-medium">
              Terms and Conditions
            </a>
            , and{' '}
            <a href="/privacy-policy" className="text-gray-900 underline font-medium">
              Privacy Policy
            </a>
          </p>
        </div>
      ) : paymentMethod === PAYMENT_METHODS.PAYPAL ? (
        <form onSubmit={handleSubmit} className="space-y-8">
          <div className="bg-gray-50 p-6 rounded-xl flex flex-col items-center justify-center text-center space-y-4">
            <FontAwesomeIcon icon={faPaypal} className="text-5xl" style={{ color: COLORS.PAYPAL }} />
            <p className="text-lg">You will be redirected to PayPal to complete your payment securely.</p>
          </div>

          <div className="flex items-center gap-2 text-gray-700">
            <FontAwesomeIcon icon={faLock} />
            <p className="text-sm">Your payment information is encrypted and secure</p>
          </div>

          <p className="text-base text-gray-600">
            By selecting the button below, I agree to the{' '}
            <a href="/terms-of-service" className="text-gray-900 underline font-medium">
              Terms and Conditions
            </a>
            , and{' '}
            <a href="/privacy-policy" className="text-gray-900 underline font-medium">
              Privacy Policy
            </a>
          </p>

          <button
            type="submit"
            disabled={loading || !stripe}
            className={`w-full py-4 text-white text-lg font-medium rounded-[2rem] transition-colors duration-200`}
            style={{ backgroundColor: COLORS.PAYPAL, hover: { backgroundColor: COLORS.PAYPAL_HOVER } }}
          >
            {loading ? 'Processing...' : 'Continue to PayPal'}
          </button>
        </form>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-8">
          <div className="space-y-4">
            <div className="bg-gray-50 p-4 rounded-xl">
              <input
                type="text"
                placeholder="Card holder"
                value={cardHolder}
                onChange={(e) => setCardHolder(e.target.value)}
                className="w-full bg-transparent border-none outline-none text-gray-700 placeholder-gray-400"
                required
                aria-label="Card holder name"
              />
            </div>
            <div className="bg-gray-50 p-4 rounded-xl">
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': {
                        color: '#aab7c4'
                      }
                    },
                    invalid: {
                      color: '#9e2146',
                      iconColor: '#9e2146'
                    }
                  },
                  hidePostalCode: false
                }}
              />
            </div>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              id="saveCard"
              checked={saveCard}
              onChange={() => setSaveCard(!saveCard)}
              className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="saveCard" className="ml-3 block text-lg text-gray-700">
              Save my card for future purchases
            </label>
          </div>

          <div className="flex items-center gap-2 text-gray-700">
            <FontAwesomeIcon icon={faLock} />
            <p className="text-sm">Your payment information is encrypted and secure</p>
          </div>

          <p className="text-base text-gray-600">
            By selecting the button below, I agree to the{' '}
            <a href="/terms-of-service" className="text-gray-900 underline font-medium">
              Terms and Conditions
            </a>
            , and{' '}
            <a href="/privacy-policy" className="text-gray-900 underline font-medium">
              Privacy Policy
            </a>
          </p>

          <button
            type="submit"
            disabled={loading || !stripe}
            className={`w-full py-4 text-white text-lg font-medium rounded-[2rem] transition-colors duration-200`}
            style={{ backgroundColor: COLORS.PRIMARY, hover: { backgroundColor: COLORS.PRIMARY_HOVER } }}
          >
            {loading ? 'Processing...' : 'Confirm and pay'}
          </button>
        </form>
      )}
    </div>
  )
}

/**
 * Checkout page component that contains the payment form
 */
function Checkout() {
  const [plan, setPlan] = useState(null)
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const planLevel = searchParams.get('level') || '2'

  useEffect(() => {
    const fetchPlan = async () => {
      try {
        const response = await getSubscriptionPlans()
        if (response.success) {
          // Find plan based on level, using the constants for plan names
          const levelName = planLevel === '1' ? 'Level 1' : 'Level 2'
          const selectedPlan = response.plans.find((p) => p.name === levelName)
          setPlan(selectedPlan)
        } else {
          toast.error(response.message || 'Failed to fetch plan details')
        }
      } catch (error) {
        console.error('Error fetching plans:', error)
        toast.error('Failed to fetch plan details')
      } finally {
        setLoading(false)
      }
    }

    fetchPlan()
  }, [planLevel])

  if (loading || !plan) {
    return <LoadingIndicator />
  }

  const stripeOptions = {
    // Automatically focus on the card element when the component mounts
    // Adding locale setting for better internationalization
    locale: 'en',
    fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Roboto' }]
  }

  return (
    <div className="container mx-auto p-6 max-w-4xl">
      <Elements stripe={stripePromise} options={stripeOptions}>
        <CheckoutForm planLevel={planLevel} priceId={plan.priceId} planAmount={plan.priceInCents} />
      </Elements>
    </div>
  )
}

export default Checkout
