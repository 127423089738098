import React, { useEffect, useState } from 'react'
import { getPrivateEventDashboardData } from '../../../services/eventService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage } from '@fortawesome/free-solid-svg-icons'

function EventDashboardModal({ showEDModal, setShowEDModal, eventDetails, handleJoinChat }) {
  const _userInfo = localStorage.getItem('user')
  const userInfo = _userInfo ? JSON.parse(_userInfo) : null

  const [loading, setLoading] = useState(false)
  const [invitations, setInvitations] = useState([])
  const [filteredInvitations, setFilteredInvitations] = useState([])
  const [acceptedNums, setAcceptedNums] = useState(0)
  const [notRespondedNums, setNotRespondedNums] = useState(0)

  const fetchFriendsOfUser = async () => {
    if (!userInfo) return
    setLoading(true)
    const response = await getPrivateEventDashboardData(userInfo.userId, eventDetails?.event_id)
    if (response && response.invitations && response.invitations.length) {
      const data = response.invitations
      // console.log('🚀 ~ fetchFriendsOfUser ~ data:', data)
      setInvitations(data)
      setFilteredInvitations(data)
      setAcceptedNums(data.filter((invitation) => invitation.status === 'Y').length)
      setNotRespondedNums(data.filter((invitation) => invitation.status === 'NR').length)
    }
    setLoading(false)
  }

  useEffect(() => {
    showEDModal && fetchFriendsOfUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEDModal])

  const handleCloseModal = () => {
    setShowEDModal(false)
  }

  const handleSearch = (search) => {
    if (search) {
      const filtered = invitations.filter((invitation) =>
        invitation.User.Profile.username.toLowerCase().includes(search.toLowerCase())
      )
      setFilteredInvitations(filtered)
    } else {
      setFilteredInvitations(invitations)
    }
  }

  return (
    <div
      className={`modal fade ${showEDModal ? 'show' : ''}`}
      style={{ display: showEDModal ? 'block' : 'none' }}
      id="event-dashboard-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="eventDashboardModalLabel"
      aria-hidden={!showEDModal}
      data-bs-backdrop="static"
    >
      <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-5 text-center sm:mt-0 sm:text-left">
                    <h3 className="text-base font-semibold text-gray-900" id="modal-title">
                      {eventDetails?.name || 'N/A'}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-4 mt-5 mb-5 max-h-96 overflow-y-auto">
                <div className="flex items-center justify-between mb-5">
                  <div className="w-1/4 mr-1 ml-1">
                    <button
                      type="button"
                      className="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-80 w-full"
                    >
                      {acceptedNums} Yes
                    </button>
                  </div>
                  <div className="w-1/2 mr-1 ml-1">
                    <button
                      type="button"
                      className="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800 w-full"
                    >
                      {notRespondedNums} Not Responded
                    </button>
                  </div>
                </div>
                <div className="flex items-end justify-end mb-5">
                  {/* <FontAwesomeIcon icon={faGear} className="ml-1 mr-2 cursor-pointer" /> */}
                  <FontAwesomeIcon icon={faMessage} className="ml-1 mr-2 cursor-pointer" onClick={handleJoinChat} />
                  {/* <FontAwesomeIcon icon={faPlus} className="ml-1 mr-2 cursor-pointer" /> */}
                </div>
                <div className="relative mr-1 ml-1 mb-5">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    id="search"
                    className="block w-full p-4 ps-10 text-sm text-blue-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 placeholder-gray-700"
                    placeholder="Search"
                    onChange={(e) => handleSearch(e.target.value)}
                    required
                  />
                </div>
                {filteredInvitations.length > 0 &&
                  filteredInvitations.map((invitation) => (
                    <div key={invitation.id} className="flex items-center justify-between py-2 ml-2 mr-2">
                      <div className="flex items-center">
                        <img
                          src={invitation?.User?.Profile?.profile_picture_url || '/assets/images/default_pfp.png'}
                          alt={invitation?.User?.Profile?.username}
                          className="h-8 w-8 rounded-full"
                        />
                        <label htmlFor={invitation.id} className="ml-3  mr-3 block text-sm font-medium text-gray-700">
                          {invitation?.User?.Profile?.username || 'N/A'}
                        </label>
                      </div>
                      <div className="justify-end justify-items-end">
                        <button
                          type="button"
                          className="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800 w-40"
                        >
                          {invitation.status}
                        </button>
                        {/* <FontAwesomeIcon icon={faMessage} className="ml-3 mr-2 text-gray-500" /> */}
                      </div>
                    </div>
                  ))}
              </div>
              {loading && (
                <div className="bg-gray-50 px-4 py-3">
                  <div className="flex items-center justify-center">
                    <div className="w-6 h-6 border-2 border-t-[4px] border-gray-900 rounded-full animate-spin"></div>
                  </div>
                </div>
              )}
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 mb-3">
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showEDModal && <div className="modal-backdrop fade show z-n1"></div>}
    </div>
  )
}

export default EventDashboardModal
