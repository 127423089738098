import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const messageStore = (set, get) => ({
  notifications: [],
  messages: {},
  isNotificationEnabled: true,
  unreadMessages: [],
  numOfNotifications: 0,
  numOfPrivateNotifications: 0,

  setMessages: (roomId, messages) => {
    set((state) => ({
      messages: {
        ...state.messages,
        [roomId]: messages
      }
    }))
  },
  addMessage: (roomId, message) => {
    set((state) => ({
      messages: {
        ...state.messages,
        [roomId]: [...(state.messages[roomId] ?? []), message]
      }
    }))
  },
  getMessages: (roomId) => {
    return get().messages[roomId] || []
  },
  addRoomIdToMessages: (roomId) => {
    if (!get().messages[roomId]) {
      set((state) => ({
        messages: {
          ...state.messages,
          [roomId]: []
        }
      }))
    }
  },
  addNotification: (notification) => {
    set((state) => {
      const existingMessage = state.unreadMessages.find((message) => message.roomId === notification.roomId)
      if (existingMessage) {
        return {
          unreadMessages: state.unreadMessages.map((message) => {
            if (message.roomId === notification.roomId) {
              return {
                ...message,
                unreadCount: message.unreadCount + 1
              }
            }
            return message
          }),
          numOfNotifications:
            notification.roomType === 'group' ? state.numOfNotifications + 1 : state.numOfNotifications,
          numOfPrivateNotifications:
            notification.roomType === 'private' ? state.numOfPrivateNotifications + 1 : state.numOfPrivateNotifications
        }
      } else {
        return {
          unreadMessages: [...state.unreadMessages, { ...notification, unreadCount: 1 }],
          numOfNotifications:
            notification.roomType === 'group' ? state.numOfNotifications + 1 : state.numOfNotifications,
          numOfPrivateNotifications:
            notification.roomType === 'private' ? state.numOfPrivateNotifications + 1 : state.numOfPrivateNotifications
        }
      }
    })
  },
  resetUnreadCountForRoom: (roomId) => {
    const roomType = get().unreadMessages.find((message) => message.roomId === roomId)?.roomType
    const unreadCount = get().unreadMessages.find((message) => message.roomId === roomId)?.unreadCount ?? 0
    const numOfNotifications = roomType === 'group' ? get().numOfNotifications - unreadCount : get().numOfNotifications
    const numOfPrivateNotifications =
      roomType === 'private' ? get().numOfPrivateNotifications - unreadCount : get().numOfPrivateNotifications

    set((state) => ({
      unreadMessages: state.unreadMessages.filter((message) => message.roomId !== roomId),
      numOfNotifications: numOfNotifications,
      numOfPrivateNotifications: numOfPrivateNotifications
    }))
  },
  resetAllNotifications: () => {
    set({
      notifications: [],
      numOfNotifications: 0
    })
  },
  toggleNotification: () => {
    set((state) => ({
      isNotificationEnabled: !state.isNotificationEnabled
    }))
  },
  setUnreadMessages: (unreadMessages) => {
    set({
      numOfNotifications: unreadMessages.reduce((acc, curr) => acc + curr.unreadCount, 0),
      unreadMessages
    })
  }
})

const useMessageStore = create(
  persist(messageStore, {
    name: 'messages-storage'
  })
)

export default useMessageStore
