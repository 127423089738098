import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useUser } from '../../../hooks/UserContext'

/**
 * Route guard component that protects routes requiring an active subscription
 * Redirects to subscription plans page if user is not subscribed
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to render if access is granted
 * @returns {React.ReactNode} Either the protected content or a redirect
 */
function SubscriptionGuard({ children }) {
  const { hasActiveSubscription, loading } = useUser()
  const location = useLocation()

  // Show nothing while checking subscription status
  if (loading) {
    return null
  }

  // If user doesn't have an active subscription, redirect to subscription plans page
  if (!hasActiveSubscription) {
    // Send the current path in state so we can redirect back after subscription
    return (
      <Navigate
        to="/subscription/plans"
        state={{
          from: location,
          message: 'This feature requires an active subscription. Please select a plan to continue.'
        }}
        replace
      />
    )
  }

  // If user has an active subscription, render the protected content
  return children
}

export default SubscriptionGuard
