import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import { useUser } from '../../hooks/UserContext'

function SubscriptionSuccess() {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const paymentIntentId = searchParams.get('payment_intent')
  const { refreshSubscriptionImmediately } = useUser()
  const [isRefreshing, setIsRefreshing] = useState(true)

  useEffect(() => {
    if (!paymentIntentId) {
      toast.error('Invalid payment session')
      navigate('/promoter')
      return
    }

    // Immediately refresh subscription status and add delay to ensure webhook processing
    const refreshSubscription = async () => {
      setIsRefreshing(true)
      try {
        setTimeout(async () => {
          await refreshSubscriptionImmediately()
          setIsRefreshing(false)
        }, 4000)
      } catch (error) {
        console.error('Error refreshing subscription:', error)
        setIsRefreshing(false)
      }
    }

    refreshSubscription()
  }, [paymentIntentId, navigate, refreshSubscriptionImmediately])

  const handleCreateEventClick = (e) => {
    if (isRefreshing) {
      e.preventDefault()
      toast.info('Please wait while we finalize your subscription...')
    }
  }

  return (
    <div className="container mx-auto p-4 flex flex-col items-center justify-center min-h-screen">
      <div className="bg-white rounded-lg shadow-md p-8 max-w-md w-full text-center">
        <FontAwesomeIcon icon={faCircleCheck} className="text-green-500 text-6xl mb-4" />
        <h1 className="text-2xl font-bold mb-4">Subscription Successful!</h1>
        <p className="text-gray-600 mb-6">Thank you for subscribing! You can now create and promote your events.</p>
        <Link
          to="/promoter/my-event/create"
          className={`bg-indigo-500 hover:bg-indigo-600 text-white font-semibold py-3 px-6 rounded block w-full ${isRefreshing ? 'opacity-70' : ''}`}
          onClick={handleCreateEventClick}
        >
          {isRefreshing ? 'Finalizing Subscription...' : 'Create Your First Event'}
        </Link>
        <Link to="/promoter" className="text-indigo-500 hover:underline mt-4 block">
          Return to Home
        </Link>
      </div>
    </div>
  )
}

export default SubscriptionSuccess
