import React, { useEffect, useState } from 'react'
import { getActivities, getFollwersOfUser, getFollowingsOfUser } from '../../services/userService'
import { useNavigate } from 'react-router-dom'
import Header from '../common/header/Header'
import { useUser } from '../../hooks/UserContext'
import { format } from 'date-fns'

export default function Dashboard() {
  const [numOfEvents, setNumOfEvents] = useState(0)
  const [numOfConnectedPeople, setNumOfConnectedPeople] = useState(0)
  const _userInfo = localStorage.getItem('user')
  const userInfo = _userInfo ? JSON.parse(_userInfo) : null
  const fullName = _userInfo ? JSON.parse(_userInfo).fullName : ''
  const [activities, setActivities] = useState([])
  const [finalPoints, setfinalPoints] = useState(0)
  const [userFollowers, setUserFollowers] = useState([])
  const [userFollowings, setUserFollowings] = useState([])
  const { subscription, refreshSubscription } = useUser()
  const navigate = useNavigate()

  const fetchUserActivities = async () => {
    if (!userInfo) return
    const data = await getActivities(userInfo.userId)
    if (data.success) {
      setActivities(data.data)
      setNumOfEvents(data.data.length)
    }
  }
  const fetchUserFollowers = async () => {
    if (!userInfo) return
    const data = await getFollwersOfUser(userInfo.userId)
    if (data.success) {
      setUserFollowers(data.data)
    }
  }

  const fetchUserFollowings = async () => {
    if (!userInfo) return
    const data = await getFollowingsOfUser(userInfo.userId)
    if (data.success) {
      setUserFollowings(data.data)
    }
  }

  useEffect(() => {
    setNumOfConnectedPeople(userFollowers.length + userFollowings.length)
  }, [userFollowings, userFollowers])

  useEffect(() => {
    fetchUserActivities()
    fetchUserFollowers()
    fetchUserFollowings()
    refreshSubscription()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const points = 750 - (userInfo?.totalPoints ?? 0)
    setfinalPoints(points)
  }, [userInfo])

  // Format subscription date for display
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A'
    try {
      return format(new Date(dateString), 'MMMM dd, yyyy')
    } catch (error) {
      return 'N/A'
    }
  }

  // Get plan name based on level
  const getPlanName = (level) => {
    if (!level) return 'No plan'
    switch (parseInt(level)) {
      case 1:
        return 'Level 1'
      case 2:
        return 'Level 2'
      default:
        return `Level ${level}`
    }
  }

  // Create the subscription card content based on subscription status
  const renderSubscriptionCard = () => {
    if (subscription?.hasActiveSubscription) {
      return (
        <div className="space-y-4">
          <div className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white p-5 rounded-t-lg shadow-inner">
            <span className="text-2xl font-bold block text-center mb-2">{getPlanName(subscription.planLevel)}</span>
            <div className="flex justify-center">
              <span className="inline-flex items-center px-6 py-1.5 rounded-md text-sm font-medium bg-green-300/80 text-green-800 shadow-sm">
                {subscription.status || 'active'}
              </span>
            </div>
          </div>
          <div className="bg-white p-5 rounded-b-lg">
            <div className="flex justify-between items-center py-3 border-b border-gray-100">
              <span className="font-medium text-gray-600">Current Period Ends:</span>
              <span className="font-semibold text-gray-800">{formatDate(subscription.currentPeriodEnd)}</span>
            </div>
            <div className="flex justify-between items-center py-3">
              <span className="font-medium text-gray-600">Benefits:</span>
              <span className="font-semibold text-gray-800">Full Access</span>
            </div>
            <div className="mt-5 text-center">
              <button
                onClick={() => navigate('/subscription/plans')}
                className="w-full px-4 py-3 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 to-purple-600 rounded-md hover:from-indigo-600 hover:to-purple-700 transition-all duration-300 ease-in-out transform hover:scale-102 shadow-md"
              >
                Manage Subscription
              </button>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="flex flex-col h-full">
          <div className="bg-white p-6 rounded-lg flex-grow flex flex-col items-center justify-center text-center space-y-4">
            <div className="w-16 h-16 mx-auto rounded-full bg-gray-100 flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <p className="text-lg font-medium text-gray-700">You don't have an active subscription.</p>
            <p className="text-sm text-gray-500">
              Unlock premium features and benefits by subscribing to one of our plans.
            </p>
            <button
              onClick={() => navigate('/subscription/plans')}
              className="mt-4 w-full px-4 py-3 text-sm font-medium text-white bg-gradient-to-r from-indigo-600 to-purple-600 rounded-md hover:from-indigo-700 hover:to-purple-700 transition-all duration-300 ease-in-out transform hover:scale-102 shadow-md"
            >
              Get a Subscription
            </button>
          </div>
        </div>
      )
    }
  }

  // Main render
  return (
    <div className="max-w-4xl mx-auto py-6 sm:px-6 lg:px-8 px-3">
      <Header />
      <h1 className="my-4 text-2xl font-semibold text-gray-900">Dashboard</h1>
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3">
        {/* Profile section */}
        <div className="sm:col-span-full">
          <div className="flex">
            <img
              src={userInfo?.profileImgUrl || '/assets/images/default_pfp.png'}
              alt="Profile"
              className="h-36 w-36 object-cover rounded-lg"
            />
            <div className="mx-4">
              <h3 className="text-xl font-medium leading-6 text-gray-900">{fullName}</h3>
              <p className="mt-2 max-w-2xl text-sm text-gray-800">Current Status</p>
              <p className="mt-1 py-1 rounded-md bg-purple-700 max-w-2xl text-white text-center text-xl">
                {userInfo?.status}
              </p>
            </div>
          </div>
          <div>
            <p>
              <button
                onClick={() => navigate('/promoter/profile')}
                className="text-white px-4 py-2 mt-5 rounded-sm gap-6 bg-[#8023eb] hover:bg-[#8023eb] w-full"
              >
                Edit Profile
              </button>
            </p>
          </div>
        </div>

        {/* Points section */}
        <div className="sm:col-span-full">
          <div className="bg-[#96f468] p-6 rounded-lg shadow-md">
            <h2 className="text-3xl font-bold mb-4 text-center">
              {userInfo?.totalPoints <= 0 ? 'Welcome To Your Dashboard' : 'Congratulations!'}
            </h2>
            <div className="mt-4">
              <p className="text-3xl font-bold mb-4 text-center">You've Earned {userInfo?.totalPoints ?? 0} Points</p>
              <p className="text-xl text-center">Only {finalPoints} Points To VIP Status</p>
            </div>
          </div>
        </div>

        {/* Subscription section */}
        <div className="sm:col-span-full">
          <div className="p-6 rounded-lg shadow-md bg-white">
            <h2 className="text-xl font-bold mb-4">Subscription Status</h2>
            {renderSubscriptionCard()}
          </div>
        </div>

        {/* Events and connections summary */}
        <div className="sm:col-span-full">
          <h2 className="text-xl text-center font-semibold">
            You`ve attended {numOfEvents} events and connected with {numOfConnectedPeople} People.
            <br /> Way To Go!
          </h2>
        </div>

        {/* Activities section */}
        <div className="sm:col-span-full">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-medium leading-6 text-gray-900">Your Activities ({activities.length})</h2>
            <div className="mt-2 text-sm text-gray-500">
              <div className="flex flex-nowrap gap-3 overflow-x-auto">
                {activities.map((activity, index) => (
                  <div className="rounded-lg shrink-0" key={index}>
                    <img src={activity.flyer} alt={activity.name} className="h-16 w-16 object-cover rounded-xl" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Followings section */}
        <div className="sm:col-span-full">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-medium leading-6 text-gray-900">Following ({userFollowings?.length})</h2>
            <p className="mt-2 max-w-2xl text-sm text-gray-500">
              <div className="flex gap-3 overflow-x-scroll">
                {userFollowings.map((userFollowing, index) => (
                  <div className="rounded-lg max-w-16" key={index}>
                    <img
                      src={userFollowing?.User?.Profile?.profile_picture_url || '/assets/images/default_pfp.png'}
                      alt={userFollowing?.username}
                      className="h-16 w-16 object-cover rounded-full"
                    />
                    <div className="relative group">
                      <div className="mt-2 overflow-x-hidden">{userFollowing?.User?.Profile?.username}</div>
                      <div className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm rounded px-2 py-1 shadow-lg">
                        {userFollowing?.User?.Profile?.username}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </p>
          </div>
        </div>

        {/* Followers section */}
        <div className="sm:col-span-full">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-medium leading-6 text-gray-900">Followers ({userFollowers?.length})</h2>
            <p className="mt-2 max-w-2xl text-sm text-gray-500">
              <div className="flex gap-3 overflow-x-scroll">
                {userFollowers.map((userFollower, index) => (
                  <div className="rounded-lg max-w-16" key={index}>
                    <img
                      src={userFollower?.FollowerUser?.Profile?.profile_picture_url || '/assets/images/default_pfp.png'}
                      alt={userFollower?.username}
                      className="h-16 w-16 object-cover rounded-full"
                    />
                    <div className="relative group">
                      <div className="mt-2 overflow-x-hidden">{userFollower?.FollowerUser?.Profile?.username}</div>
                      <div className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm rounded px-2 py-1 shadow-lg">
                        {userFollower?.FollowerUser?.Profile?.username}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
