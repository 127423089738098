import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  getSubscriptionPlans,
  updateSubscriptionPlan,
  changeSubscription,
  cancelSubscription,
  reactivateSubscription
} from '../../services/subscriptionService'
import { toast } from 'react-toastify'
import { loadStripe } from '@stripe/stripe-js'
import { useUser } from '../../hooks/UserContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faCheck, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { STRIPE_PUBLISHABLE_KEY } from '../../config/constants'
import { LoadingIndicator } from '../../components/common/loading'
loadStripe(STRIPE_PUBLISHABLE_KEY)

/**
 * Subscription plans component that displays available subscription options
 */
function SubscriptionPlans() {
  const [plans, setPlans] = useState([])
  const [loading, setLoading] = useState(true)
  const [editingPlan, setEditingPlan] = useState(null)
  const navigate = useNavigate()
  const { user, refreshSubscription, subscription, loading: userLoading } = useUser()
  const [subscriptionLoaded, setSubscriptionLoaded] = useState(false)
  const [showCancelConfirm, setShowCancelConfirm] = useState(false)
  const [processingAction, setProcessingAction] = useState(false)
  const plansLoaded = useRef(false)

  // Memoized fetchPlans function to prevent recreating it on every render
  const fetchPlans = useCallback(async () => {
    if (plansLoaded.current) return

    try {
      setLoading(true)
      const { success, plans } = await getSubscriptionPlans()
      if (success) {
        setPlans(plans)
        plansLoaded.current = true
      }
    } catch (error) {
      console.error('Error fetching plans:', error)
      toast.error('Failed to load subscription plans')
    } finally {
      setLoading(false)
    }
  }, [])

  // Separate effect for fetching plans - only runs once
  useEffect(() => {
    fetchPlans()
  }, [fetchPlans])

  // Separate effect for handling subscription data
  useEffect(() => {
    // If subscription data isn't available immediately, try to refresh it
    if (!subscription && user?.userId && !subscriptionLoaded) {
      refreshSubscription().then(() => {
        setSubscriptionLoaded(true)
      })
    } else if (!subscriptionLoaded) {
      setSubscriptionLoaded(true)
    }
  }, [user, subscription, refreshSubscription, subscriptionLoaded])

  const handleUpgrade = async (planLevel, priceId) => {
    if (!user) {
      toast.error('Please login to continue')
      return
    }

    // If user doesn't have an active subscription, redirect to checkout
    if (!subscription?.hasActiveSubscription) {
      navigate(`/subscription/checkout?level=${planLevel}&priceId=${priceId}`)
      return
    }

    // If user has an active subscription, handle upgrade/downgrade in place
    try {
      setProcessingAction(true)
      const result = await changeSubscription(user.userId, planLevel, priceId)

      // Get updated subscription information
      const updatedSubscription = await refreshSubscription()

      // Force update of UI immediately with the new plan info
      if (updatedSubscription) {
        const newPlanInt = parseInt(planLevel)
        const currentPlanInt = parseInt(subscription.planLevel)
        const isUpgrade = newPlanInt > currentPlanInt

        if (isUpgrade) {
          // For upgrades, immediately update UI since backend makes immediate changes
          toast.success(result.message)
        } else {
          // For downgrades, show a message that it'll take effect later
          toast.info(result.message)
        }
      } else {
        toast.success(result.message)
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to change subscription')
    } finally {
      setProcessingAction(false)
    }
  }

  const handleCancelSubscription = async () => {
    if (!user || !subscription?.hasActiveSubscription) {
      return
    }

    try {
      setProcessingAction(true)
      const result = await cancelSubscription(user.userId)

      // Get updated subscription information
      await refreshSubscription()

      setShowCancelConfirm(false)
      toast.success(result.message)
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to cancel subscription')
    } finally {
      setProcessingAction(false)
    }
  }

  const handleReactivateSubscription = async () => {
    if (!user || !subscription?.hasActiveSubscription) {
      return
    }

    try {
      setProcessingAction(true)
      const result = await reactivateSubscription(user.userId)

      // Get updated subscription information
      await refreshSubscription()

      toast.success(result.message)
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to reactivate subscription')
    } finally {
      setProcessingAction(false)
    }
  }

  const handleUpdatePlan = async () => {
    try {
      await updateSubscriptionPlan(editingPlan.id, editingPlan)
      setEditingPlan(null)

      // Only force a refresh of plans when a plan is actually updated
      plansLoaded.current = false
      await fetchPlans()

      toast.success('Plan updated successfully')
    } catch (error) {
      toast.error('Failed to update plan')
    }
  }

  const handleFeatureChange = (index, value) => {
    const updatedFeatures = [...editingPlan.features]
    updatedFeatures[index] = value
    setEditingPlan({ ...editingPlan, features: updatedFeatures })
  }

  const handleAddFeature = () => {
    setEditingPlan({
      ...editingPlan,
      features: [...editingPlan.features, '']
    })
  }

  const handleRemoveFeature = (index) => {
    const updatedFeatures = editingPlan.features.filter((_, i) => i !== index)
    setEditingPlan({ ...editingPlan, features: updatedFeatures })
  }

  // Render loading state if plans or user data is still loading
  if (loading || userLoading) {
    return <LoadingIndicator />
  }

  const renderSubscriptionButton = (plan) => {
    // Handle the case when subscription data is still loading
    if (!subscriptionLoaded) {
      return (
        <button disabled className="w-full font-semibold py-4 px-6 rounded-xl bg-gray-100 text-gray-400 cursor-wait">
          Loading subscription info...
        </button>
      )
    }

    const isCurrentPlan =
      subscription?.hasActiveSubscription && parseInt(subscription.planLevel) === parseInt(plan.level)

    // Check if the current subscription is scheduled for cancellation
    // const isCancelling = subscription?.hasActiveSubscription && subscription.cancelAtPeriodEnd

    const buttonClass = isCurrentPlan
      ? 'bg-green-100 text-green-700 border-2 border-green-300 cursor-default'
      : plan.isPopular
        ? 'bg-indigo-600 hover:bg-indigo-700 text-white shadow-md hover:shadow-lg'
        : 'bg-white hover:bg-gray-100 text-indigo-600 border-2 border-indigo-600 hover:border-indigo-700'

    // If the subscription is being processed, show loading state
    if (processingAction) {
      return (
        <button disabled className="w-full font-semibold py-4 px-6 rounded-xl bg-gray-100 text-gray-400 cursor-wait">
          Processing...
        </button>
      )
    }

    let buttonText = ''
    if (isCurrentPlan) {
      buttonText = 'Current Plan'
    } else if (plan.isPopular) {
      buttonText =
        subscription?.hasActiveSubscription && parseInt(subscription.planLevel) < parseInt(plan.level)
          ? `Upgrade to ${plan.name}`
          : 'Get Started Now'
    } else {
      buttonText =
        subscription?.hasActiveSubscription && parseInt(subscription.planLevel) > parseInt(plan.level)
          ? `Downgrade to ${plan.name}`
          : `Choose ${plan.name}`
    }

    return (
      <button
        onClick={() => handleUpgrade(plan.level, plan.priceId)}
        disabled={isCurrentPlan}
        className={`w-full font-semibold py-4 px-6 rounded-xl transition-all duration-300 ${buttonClass}`}
      >
        {buttonText}
      </button>
    )
  }

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold text-center mb-4">Choose Your Plan</h1>
      <p className="text-lg text-center text-gray-600 mb-12 max-w-3xl mx-auto">
        Increase your reach by promoting your own events directly in our platform. Our AI can do all of the heavy
        lifting, from creating the flyer to notifying your ideal clientele.
      </p>

      {editingPlan ? (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <h2 className="text-2xl font-bold mb-4">Edit Plan: {editingPlan.name}</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                <input
                  type="text"
                  value={editingPlan.description}
                  onChange={(e) => setEditingPlan({ ...editingPlan, description: e.target.value })}
                  className="w-full p-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Price</label>
                <input
                  type="number"
                  value={editingPlan.price}
                  onChange={(e) => setEditingPlan({ ...editingPlan, price: parseFloat(e.target.value) })}
                  className="w-full p-2 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Features</label>
                {editingPlan.features.map((feature, index) => (
                  <div key={index} className="flex gap-2 mb-2">
                    <input
                      type="text"
                      value={feature}
                      onChange={(e) => handleFeatureChange(index, e.target.value)}
                      className="flex-1 p-2 border rounded-lg"
                    />
                    <button
                      onClick={() => handleRemoveFeature(index)}
                      className="px-3 py-2 bg-red-100 text-red-600 rounded-lg hover:bg-red-200"
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  onClick={handleAddFeature}
                  className="w-full py-2 mt-2 bg-indigo-50 text-indigo-600 rounded-lg hover:bg-indigo-100"
                >
                  Add Feature
                </button>
              </div>
            </div>
            <div className="flex gap-4 mt-6">
              <button
                onClick={handleUpdatePlan}
                className="flex-1 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Save Changes
              </button>
              <button
                onClick={() => setEditingPlan(null)}
                className="flex-1 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : null}

      <div className="grid md:grid-cols-2 gap-4 mb-8">
        {plans.map((plan) => (
          <div
            key={plan.id}
            className={`rounded-2xl overflow-hidden transition-all duration-300 hover:shadow-xl flex flex-col 
              ${plan.isPopular ? 'border-2 border-indigo-500 shadow-lg' : 'border border-gray-200 shadow-md'}`}
          >
            <div
              className={`p-8 ${plan.isPopular ? 'bg-gradient-to-br from-indigo-500 to-purple-600 text-white' : 'bg-white'}`}
            >
              {plan.isPopular && (
                <div className="flex items-center justify-start mb-2 text-yellow-300">
                  <FontAwesomeIcon icon={faStar} className="mr-2" />
                  <span className="font-semibold">MOST POPULAR</span>
                </div>
              )}
              <div className="flex justify-between items-start">
                <div>
                  <h2 className={`text-3xl font-bold mb-1 ${plan.isPopular ? 'text-white' : 'text-indigo-600'}`}>
                    {plan.name}
                  </h2>
                  <p className="text-lg mb-3 opacity-90">{plan.description}</p>
                </div>
                <div className="text-right">
                  <div className="text-3xl font-bold mb-1">${plan.price}</div>
                  <div className="opacity-80">per {plan.interval || 'month'}</div>
                </div>
              </div>
            </div>
            <div className="p-8 bg-white flex-1 flex flex-col">
              <ul className="mb-8 flex-1">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-start mb-4">
                    <div
                      className={`flex-shrink-0 w-5 h-5 mt-1 mr-3 rounded-full flex items-center justify-center ${
                        plan.isPopular ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-600'
                      }`}
                    >
                      <FontAwesomeIcon icon={faCheck} className="text-xs" />
                    </div>
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              {renderSubscriptionButton(plan)}
            </div>
          </div>
        ))}
      </div>

      {/* Subscription management section */}
      {subscription?.hasActiveSubscription && (
        <div className="max-w-4xl mx-auto">
          <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
            <div className="bg-gradient-to-r from-indigo-500 to-purple-600 px-6 py-4">
              <h2 className="text-lg font-bold text-white flex items-center">
                <FontAwesomeIcon icon={faPencilAlt} className="mr-3" />
                Subscription Management
              </h2>
            </div>

            <div className="p-8">
              {/* Subscription Status Card */}
              <div className="mb-8 bg-gray-50 rounded-xl p-6 border border-gray-100">
                <div className="grid md:grid-cols-3 gap-6">
                  <div>
                    <h3 className="text-sm font-medium text-gray-500 mb-1">Current Plan</h3>
                    <p className="text-xl font-semibold text-gray-900">Level {subscription.planLevel}</p>
                  </div>
                  <div>
                    <h3 className="text-sm font-medium text-gray-500 mb-1">Billing Period</h3>
                    <p className="text-xl font-semibold text-gray-900">
                      {new Date(subscription.currentPeriodEnd).toLocaleDateString()}
                    </p>
                  </div>
                  <div>
                    <h3 className="text-sm font-medium text-gray-500 mb-1">Status</h3>
                    <div className="flex items-center">
                      <div
                        className={`w-2 h-2 rounded-full mr-2 ${
                          subscription.isTrial
                            ? 'bg-blue-500'
                            : subscription.cancelAtPeriodEnd
                              ? 'bg-amber-500'
                              : 'bg-green-500'
                        }`}
                      ></div>
                      <p className="text-xl font-semibold text-gray-900">
                        {subscription.isTrial ? 'Trial' : subscription.cancelAtPeriodEnd ? 'Canceling' : 'Active'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Trial Notice */}
              {subscription.isTrial && (
                <div className="mb-8 bg-blue-50 rounded-xl p-6 border border-blue-100">
                  <div className="flex items-start">
                    <div className="flex-shrink-0 bg-blue-100 rounded-full p-2 mr-4">
                      <FontAwesomeIcon icon={faStar} className="text-blue-600 text-xl" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-blue-900 mb-1">Free Trial Period</h3>
                      <p className="text-blue-700">
                        Your trial will end on {new Date(subscription.trialEnd).toLocaleDateString()}. After this date,
                        your payment method will be charged automatically to continue your subscription.
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {/* Cancellation Notice */}
              {subscription.cancelAtPeriodEnd ? (
                <div className="mb-8">
                  <div className="bg-amber-50 rounded-xl p-6 border border-amber-100 mb-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0 bg-amber-100 rounded-full p-2 mr-4">
                        <FontAwesomeIcon icon={faStar} className="text-amber-600 text-xl" />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-amber-900 mb-1">Subscription Ending Soon</h3>
                        <p className="text-amber-700">
                          Your subscription is scheduled to end on{' '}
                          {new Date(subscription.currentPeriodEnd).toLocaleDateString()}. You'll continue to have access
                          to all features until this date.
                        </p>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={handleReactivateSubscription}
                    disabled={processingAction}
                    className="w-full md:w-auto px-6 py-3 bg-indigo-600 text-white rounded-xl font-semibold
                             hover:bg-indigo-700 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed
                             flex items-center justify-center"
                  >
                    {processingAction ? (
                      <>
                        <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent mr-2"></div>
                        Processing...
                      </>
                    ) : (
                      'Continue Subscription'
                    )}
                  </button>
                </div>
              ) : (
                <div className="flex flex-col md:flex-row items-center justify-between gap-4">
                  <p className="text-gray-600">
                    Your {subscription.isTrial ? 'paid subscription' : 'subscription'} will{' '}
                    {subscription.isTrial ? 'start' : 'renew'} on{' '}
                    {new Date(subscription.currentPeriodEnd).toLocaleDateString()}.
                  </p>
                  <button
                    onClick={() => setShowCancelConfirm(true)}
                    disabled={processingAction}
                    className="w-full md:w-auto px-6 py-3 bg-white border-2 border-red-500 text-red-500 rounded-xl 
                             font-semibold hover:bg-red-50 transition-colors duration-200 disabled:opacity-50 
                             disabled:cursor-not-allowed flex items-center justify-center"
                  >
                    {processingAction ? (
                      <>
                        <div className="animate-spin rounded-full h-4 w-4 border-2 border-red-500 border-t-transparent mr-2"></div>
                        Processing...
                      </>
                    ) : (
                      'Cancel Subscription'
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Cancel confirmation modal */}
      {showCancelConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-[999999]">
          <div className="bg-white rounded-2xl p-8 max-w-lg w-full">
            <div className="text-center mb-6">
              <div className="bg-red-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <FontAwesomeIcon icon={faStar} className="text-red-600 text-2xl" />
              </div>
              <h2 className="text-2xl font-bold text-gray-900 mb-2">Cancel Your Subscription?</h2>
              <p className="text-gray-600">
                Your subscription will remain active until{' '}
                {new Date(subscription.currentPeriodEnd).toLocaleDateString()}. After this date, you'll lose access to
                premium features.
              </p>
            </div>
            <div className="flex flex-col sm:flex-row gap-4">
              <button
                onClick={handleCancelSubscription}
                disabled={processingAction}
                className="flex-1 py-3 px-6 bg-red-600 text-white rounded-xl font-semibold
                         hover:bg-red-700 transition-colors duration-200 disabled:opacity-50 
                         disabled:cursor-not-allowed flex items-center justify-center"
              >
                {processingAction ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent mr-2"></div>
                    Processing...
                  </>
                ) : (
                  'Yes, Cancel'
                )}
              </button>
              <button
                onClick={() => setShowCancelConfirm(false)}
                disabled={processingAction}
                className="flex-1 py-3 px-6 bg-gray-100 text-gray-700 rounded-xl font-semibold
                         hover:bg-gray-200 transition-colors duration-200 disabled:opacity-50 
                         disabled:cursor-not-allowed"
              >
                No, Keep My Plan
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SubscriptionPlans
