import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { formatEventDate } from '../../../services/eventService'

export const EventCard = ({ event, showFollowButton, onFollowUpdate, showUserName }) => {
  const [isFollowing, setIsFollowing] = useState(event?.User?.is_following || false)

  // eslint-disable-next-line no-unused-vars
  const handleFollowChange = (userId, eventName) => {
    setIsFollowing((prev) => !prev)
    const data = {
      action: isFollowing ? 'unfollow' : 'follow',
      user_id: userId,
      name: eventName
    }
    if (onFollowUpdate) {
      onFollowUpdate(data)
    } else {
      console.warn('You must pass an event handler to this function')
    }
  }

  if (!event) return null

  return (
    <div className="my-6 event relative max-w-[720px] mx-auto">
      {event.flyer && (
        <Link
          to={`/promoter/event/${event.slug}`}
          state={{ fromEventsList: true, eventId: event.id }}
          className="text-decoration-none"
        >
          <div className="relative mb-4">
            <Link to={`/promoter/event/${event.slug}`}>
              <div className="text-sm font-bold ml-2">
                {event.name} <br />{' '}
                <span className="text-xs text-capitalize text-gray-400 inter-400">
                  {event.venue_name ? `${event.venue_name}, ` : ''} {event.city}{' '}
                </span>
              </div>
            </Link>
            <div className="z-30 card-img-top rounded-3 relative">
              <img src={event.flyer} alt={event.name || 'Event'} />
            </div>
            <div className="overlay absolute  flex justify-between w-full top-6 mt-6 left-0 p-2 z-40">
              <button className="items-left justify-center text-white border border-gray-200 font-semibold bg-gray-500 opacity-70 rounded hover:bg-blue-800">
                {formatEventDate(event.start)}
              </button>
              {/* {event.is_public === 0 && (
                <span className="z-40 absolute top-2 right-1 rounded bg-primary py-1 px-3 text-sm font-light text-white epilogue-400">
                  Private
                </span>
              )} */}
              <Link
                to={`/promoter/event/${event.slug}`}
                className="bg-indigo-500 hover:bg-indigo-500 text-white font-bold opacity-80 py-2 px-4 border border-blue-700 rounded"
              >
                More Details
              </Link>
            </div>
          </div>
        </Link>
      )}
    </div>
  )
}
