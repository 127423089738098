import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUser } from '../../hooks/UserContext'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import axios from 'axios'
import config from '../../config/config'
import { LoadingIndicator } from '../../components/common/loading'

/**
 * Component to handle PayPal redirect and payment completion
 */
function PaypalRedirect() {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const location = useLocation()
  const navigate = useNavigate()
  const { user, loading: userLoading } = useUser()
  const searchParams = new URLSearchParams(location.search)
  const clientSecret = searchParams.get('client_secret')
  const customerId = searchParams.get('customer_id')
  const planLevel = searchParams.get('plan_level')

  useEffect(() => {
    // Only proceed with checks after user data has finished loading
    if (userLoading) {
      return
    }

    // Redirect to login if user is not authenticated
    if (!user) {
      toast.error('Please log in to continue with your subscription')
      // Store the current URL to redirect back after login
      sessionStorage.setItem('redirectAfterLogin', `/subscription/checkout?level=${planLevel || '2'}`)
      navigate('/login')
      return
    }

    const initiatePaypalCheckout = async () => {
      try {
        if (!clientSecret || !customerId || !planLevel) {
          throw new Error('Missing required parameters')
        }

        // Create PayPal checkout session
        const response = await axios.post(
          `${config.BASE_API_URL}/stripe/create-paypal-session`,
          {
            clientSecret,
            customerId,
            planLevel
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get('gn_userToken')}`
            }
          }
        )

        if (!response.data.success) {
          throw new Error(response.data.message || 'Failed to create PayPal session')
        }

        // Redirect to PayPal checkout URL
        window.location.href = response.data.redirectUrl
      } catch (error) {
        setError(error.message || 'Something went wrong with PayPal checkout')
        toast.error(error.message || 'Failed to initiate PayPal checkout')
        setLoading(false)

        // Redirect back to checkout after a delay
        setTimeout(() => {
          navigate(`/subscription/checkout?level=${planLevel || '2'}`)
        }, 3000)
      }
    }

    initiatePaypalCheckout()
  }, [clientSecret, customerId, planLevel, navigate, user, userLoading])

  const returnToCheckout = () => {
    navigate(`/subscription/checkout?level=${planLevel || '2'}`)
  }

  // Show loading state while user data is being fetched
  if (userLoading) {
    return <LoadingIndicator />
  }

  // Show loading state for PayPal checkout
  if (loading && user) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen p-6">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500 mb-4"></div>
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Connecting to PayPal...</h2>
        <p className="text-gray-600">Please wait while we redirect you to the PayPal checkout.</p>
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen p-6 text-center">
        <div className="bg-red-100 p-4 rounded-full mb-4">
          <svg className="w-12 h-12 text-red-500 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        </div>
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">PayPal Checkout Failed</h2>
        <p className="text-gray-600 mb-6">{error}</p>
        <button
          onClick={returnToCheckout}
          className="px-6 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition-colors"
        >
          Return to Checkout
        </button>
      </div>
    )
  }

  return null
}

export default PaypalRedirect
