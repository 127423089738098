import React, { useEffect, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faBell } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { useScroll } from '../../../hooks/ScrollContext'

import { Menu, MenuItem, MenuItems, MenuButton, Transition } from '@headlessui/react'
import useMessageStore from '../../../store/messageStore'

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

export default function Header(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const { event, auth, showLink, title } = props
  const { saveScrollPosition, getScrollPosition } = useScroll()
  const _userInfo = localStorage.getItem('user')
  const userInfo = _userInfo ? JSON.parse(_userInfo) : null
  const numOfPrivateNotifications = useMessageStore((state) => state.numOfPrivateNotifications)
  const unreadMessages = useMessageStore((state) => state.unreadMessages)
  const privateNotifications = unreadMessages.filter((message) => message.roomType === 'private')

  const handleBackArrow = () => {
    const currentPage = window.location.pathname
    saveScrollPosition(currentPage, window.scrollY)

    if (event?.invite) {
      navigate(`/event-goer/event/${event?.invite?.event}/influencer/${event?.invite?.influencerId}`)
    } else {
      navigate(-1)
    }
  }

  // Save scroll position when unmounting
  useEffect(() => {
    const handleScroll = () => {
      saveScrollPosition(location.pathname, window.scrollY)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [location.pathname, saveScrollPosition])

  // Restore scroll position on navigation
  useEffect(() => {
    const position = getScrollPosition(location.pathname)

    window.scrollTo(0, position, { behavior: 'smooth' })
  }, [location.pathname, getScrollPosition])

  if (auth === 'unauthenticated') {
    return (
      <header className="pt-4">
        {showLink && (
          <button onClick={handleBackArrow}>
            <img src="/assets/images/icons/left-arrow.svg" alt="Nevigate to event details" />
          </button>
        )}
        <div className="text-center mb-4">
          <img src="/assets/images/logo.png" alt="GreatNight Logo" />
          <h2 id="tagline" className="mt-3">
            Your Social Life Just Got Smarter
          </h2>
        </div>
        {event?.invite && (
          <div>
            <h2 className="mb-1 text-center inter-600">Get Your Ticket!</h2>
            <h3 className="mb-5 text-center inter-600">{event.invite?.eventName}</h3>
          </div>
        )}
      </header>
    )
  } else {
    return (
      <header id="primary-header" className="container pt-4">
        <div className="grid grid-cols-12 mb-5 gap-2">
          <div className="col-span-7">
            <img
              src="/assets/images/logo.png"
              alt="GreatNight Logo"
              id="logo"
              className="max-w-[250px]"
              onClick={() => navigate('/promoter/')}
            />
          </div>
          {userInfo && (
            <div className="col-span-5 flex items-center justify-end gap-3">
              <Menu as="div" className="relative">
                <MenuButton className="-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">Open user menu</span>
                  <div class="relative inline-flex">
                    <FontAwesomeIcon icon={faBell} className="icon-hover h-6 w-6" onClick={() => {}} />
                    {numOfPrivateNotifications > 0 && (
                      <span className="absolute top-0 right-0.5 grid min-h-[24px] min-w-[24px] translate-x-2/4 -translate-y-2/4 place-items-center rounded-full bg-red-600 py-1 px-1 text-xs text-white">
                        {numOfPrivateNotifications}
                      </span>
                    )}
                  </div>
                </MenuButton>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  {privateNotifications.length > 0 ? (
                    <MenuItems className="absolute right-0 z-10 mt-2.5 w-80 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {privateNotifications.map((item) => (
                        <MenuItem key={item.uid}>
                          {({ focus }) => (
                            <button
                              href={item.href}
                              onClick={async () => {
                                navigate(`/chatroom/${item.roomId}`)
                              }}
                              className={classNames(
                                focus ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                              )}
                            >
                              New message from {item.sender_id}
                            </button>
                          )}
                        </MenuItem>
                      ))}
                    </MenuItems>
                  ) : (
                    <MenuItems className="absolute right-0 z-10 mt-2.5 w-80 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <div className="block px-3 py-1 text-sm leading-6 text-gray-900">No new notifications</div>
                    </MenuItems>
                  )}
                </Transition>
              </Menu>
              <img
                src={userInfo?.profileImgUrl || '/assets/images/default_pfp.png'}
                alt="Profile"
                className="h-8 w-8 object-cover rounded-full"
                onClick={() => {
                  navigate(`/profile/` + userInfo?.userId)
                }}
              />
            </div>
          )}
        </div>
        <div className="flex justify-between items center mb-4 relative">
          {showLink && (
            <button onClick={handleBackArrow} className="flex items-center gap-2">
              <FontAwesomeIcon icon={faChevronLeft} className="block text-black" />
              <span className="block">Back</span>
            </button>
          )}
          {title && <h3 className="text-zinc-800 text-xl font-medium font-roboto leading-7">{title}</h3>}
        </div>
      </header>
    )
  }
}
