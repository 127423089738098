import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, Route, RouterProvider, Routes, useLocation } from 'react-router-dom'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import RegisterContainer from './containers/RegisterContainer'
import TicketsContainer from './containers/TicketsContainer'
import Events from './components/event/list/Events'
import EventCreateContainer from './containers/EventCreateContainer'
import EventDetailsContainer from './containers/EventDetailsContainer'
import PrivacyPolicy from './components/legal/PrivacyPolicy'
import TermsOfService from './components/legal/TermsOfService'
import TicketDetailsContainer from './containers/TicketDetailsContainer'
import TicketScanContainer from './containers/TicketScanContainer'
import LoginContainer from './containers/LoginContainer'
import ForgotPasswordContainer from './containers/ForgotPasswordContainer'
import ResetPasswordContainer from './containers/ResetPasswordContainer'
import ProfileContainer from './containers/ProfileContainer'
import PublicProfileContainer from './containers/PublicProfileContainer'
import EventEditContainer from './containers/EventEditContainer'
import MyEvents from './components/event/promoter/my-events/MyEvents'
import VibesList from './components/vibe/VibesList'
import Vibes from './components/vibe/Vibes'
import CreateProfileContainer from './containers/CreateProfileContainer'
import PersonalizeContainer from './containers/PersonalizeContainer'
import Dashboard from './components/dashboard'
import Help from './components/help/Help'
import ChatRoom from './components/chatroom'
import PlaceholderContainer from './containers/PlaceholderContainer'
import VibesMediaUpload from './components/vibe/VibesMediaUpload'
import AllVibes from './components/vibe/AllVibes'
import SubscriptionPlans from './pages/Subscription/SubscriptionPlans'
import Checkout from './pages/Subscription/Checkout'
import SubscriptionSuccess from './pages/Subscription/SubscriptionSuccess'
import PaypalRedirect from './pages/Subscription/PaypalRedirect'
import SubscriptionGuard from './components/common/RouteGuards/SubscriptionGuard'

const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    children: [
      {
        path: '',
        element: <Events />
      },
      {
        path: 'login',
        element: <LoginContainer />
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordContainer />
      },
      {
        path: 'reset-password',
        element: <ResetPasswordContainer />
      },
      {
        path: 'register',
        element: <RegisterContainer />
      },
      {
        path: 'create-profile',
        element: <CreateProfileContainer />
      },
      {
        path: 'personalize-tags',
        element: <PersonalizeContainer />
      },
      {
        path: 'event/rsvp/:rsvpId/:influencerId/scan-qr-callback',
        element: <TicketScanContainer />
      },
      {
        path: 'dashboard',
        element: <Dashboard />
      },
      {
        path: 'chatroom/:roomId',
        element: <ChatRoom />
      },
      { path: 'camera', element: <PlaceholderContainer /> },
      { path: 'notifications', element: <PlaceholderContainer /> },
      { path: 'paypal-redirect', element: <PaypalRedirect /> },
      {
        path: 'profile/:userId',
        element: <PublicProfileContainer />
      }
    ]
  },
  {
    path: '/promoter',
    element: <App />,
    children: [
      {
        path: '',
        element: <Events />
      },
      {
        path: 'event/:eventId',
        element: <EventDetailsContainer />
      },
      {
        path: 'event/edit/:eventId',
        element: <EventEditContainer />
      },
      {
        path: 'register',
        element: <RegisterContainer />
      },
      {
        path: 'login',
        element: <LoginContainer />
      },
      {
        path: 'event/tickets',
        element: <TicketsContainer />
      },
      {
        path: 'event/tickets/:ticketId',
        element: <TicketDetailsContainer />
      },
      {
        path: 'my-event',
        element: <MyEvents />
      },
      {
        path: 'my-event/create',
        element: (
          <SubscriptionGuard>
            <EventCreateContainer />
          </SubscriptionGuard>
        )
      },
      {
        path: 'my-event/:eventId',
        element: <EventDetailsContainer />
      },
      {
        path: 'profile',
        element: <ProfileContainer />
      },
      {
        path: 'event/vibes',
        element: <VibesList />
      },

      {
        path: 'event/all-vibes',
        element: <AllVibes />
      },

      {
        path: 'vibes/:id',
        element: <Vibes />
      },
      {
        path: 'vibes/:id/add-media',
        element: <VibesMediaUpload />
      },
      {
        path: 'vibes/influencer/:influencerId',
        element: <Vibes />
      },
      {
        path: 'help',
        element: <Help />
      }
    ]
  },
  {
    path: '/event-goer',
    element: <App />,
    children: [
      {
        path: '',
        element: <Events />
      },
      {
        path: 'event/:eventId',
        element: <EventDetailsContainer />
      },
      {
        path: 'event/:eventId/invite/:inviteId/recipient/:recipientId',
        element: <EventDetailsContainer />
      },
      {
        path: 'event/:eventId/influencer/:influencerId',
        element: <EventDetailsContainer />
      },
      {
        path: 'event/:eventId/influencer/:influencerId/ticket/register',
        element: <RegisterContainer />
      },
      {
        path: 'login',
        element: <LoginContainer />
      },
      {
        path: 'event/tickets',
        element: <TicketsContainer />
      },
      {
        path: 'event/tickets/:ticketId',
        element: <TicketDetailsContainer />
      },
      {
        path: 'profile',
        element: <ProfileContainer />
      }
    ]
  },
  {
    path: '/privacy-policy',
    element: <App />,
    children: [
      {
        path: '',
        element: <PrivacyPolicy />
      }
    ]
  },
  {
    path: '/terms-of-service',
    element: <App />,
    children: [
      {
        path: '',
        element: <TermsOfService />
      }
    ]
  },
  {
    path: '/subscription',
    element: <App />,
    children: [
      {
        path: 'plans',
        element: <SubscriptionPlans />
      },
      {
        path: 'checkout',
        element: <Checkout />
      },
      {
        path: 'success',
        element: <SubscriptionSuccess />
      },
      {
        path: 'cancel',
        element: <SubscriptionPlans />
      }
    ]
  }
])

const RouteChangeTracker = ({ onRouteChange }) => {
  const location = useLocation()

  React.useEffect(() => {
    onRouteChange()
  }, [location, onRouteChange])

  return null
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router}>
      <Routes>
        <Route path="/" element={<App />} />
      </Routes>
      <RouteChangeTracker
        onRouteChange={() => {
          console.log('Route transition detected.')
          reportWebVitals((metric) => {
            console.log(`${metric.name}:`, metric)
          })
        }}
      />
    </RouterProvider>
  </React.StrictMode>
)

reportWebVitals((metric) => {
  console.log(`${metric.name}:`, metric)
})
