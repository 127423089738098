import React, { useCallback, useRef, useState, useEffect } from 'react'

import './EventCreate.css'
import Map from '../../common/map/Map'
import AddressAutocomplete from '../../common/address-autocomplete/AddressAutocomplete'
import Editor from '../../common/editor/Editor'
import DOMPurify from 'dompurify'
import { generateTimeOptions } from '../../../utils/timeUtils'
import FlyerThumbnails from './FlyerThumbnails'
import { EVENT_CATEGORIES, EVENT_ETHNICITY, EVENT_MODELS, EVENT_STATES } from '../../../config/eventData'
import { LoadingIndicator } from '../../common/loading'
import { getEventDataFromText, getEventUsageStats } from '../../../services/eventService'
import moment from 'moment-timezone'
import Header from '../../common/header/Header'
import SubscriptionLimitInfo from './SubscriptionLimitInfo'

function EventCreate({
  formData,
  setFormData,
  selectedFile,
  setSelectedFile,
  selectedName,
  handleFileChange,
  handleChange,
  handleSubmit,
  handleVenueSelected,
  address,
  disableButton,
  imageLoading,
  errors,
  handleInputBlur,
  setShowToast,
  showToast,
  toastMessage,
  loading,
  generateFlyer,
  images,
  imageURL,
  setImageURL,
  analyzeStatus
}) {
  const quillRef = useRef(null)
  const timeOptions = generateTimeOptions()
  const [createMode, setCreateMode] = useState('')
  const [quillText, setQuillText] = useState('')
  const [analyzeQuillText, setAnalyzeQuillText] = useState(null)
  const [showBtnLoading, setShowBtnLoading] = useState(false)
  const [subscriptionData, setSubscriptionData] = useState(null)

  useEffect(() => {
    const fetchEventUsage = async () => {
      try {
        const response = await getEventUsageStats()
        if (response.success) {
          setSubscriptionData({
            planLevel: response.data.planLevel,
            eventsThisMonth: response.data.eventsCreated,
            maxEventsPerMonth: response.data.maxEventsPerMonth
          })
        }
      } catch (error) {
        console.error('Error fetching event usage data:', error)
      }
    }

    fetchEventUsage()
  }, [])

  const handleTextChange = useCallback(() => {
    const quill = quillRef.current
    const description = quill.root.innerHTML
    const sanitizedDescription = DOMPurify.sanitize(description)
    const normalizedDescription = sanitizedDescription.replace(/<[^>]*>?/gm, '')
    setQuillText(normalizedDescription)

    setFormData((prevFormData) => ({
      ...prevFormData,
      description: sanitizedDescription
    }))
  }, [setFormData])

  const analyzeText = async () => {
    if (quillText) {
      const data = {
        description: quillText
      }
      try {
        setShowBtnLoading(true)
        const response = await getEventDataFromText(JSON.stringify(data))
        console.log('getEventDataFromText response:', response)
        setAnalyzeQuillText(response)
        if (response.success) {
          const event = response.event
          setFormData((prevFormData) => ({
            ...prevFormData,
            name: prevFormData.name || event.name,
            description: event.description || prevFormData.description,
            category: event.category || prevFormData.category,
            tags: event.tags || prevFormData.tags,
            venueName: event.venue_name || prevFormData.venueName,
            street: event.street || prevFormData.street,
            city: event.city || prevFormData.city,
            state: event.state || prevFormData.state,
            zip: event.zip || prevFormData.zip,
            country: event.country || prevFormData.country,
            start: moment(event.start).format('hh:mm A') || prevFormData.start,
            end: moment(event.end).format('hh:mm A') || prevFormData.end,
            startDate: moment(event.start).format('YYYY-MM-DD') || prevFormData.startDate,
            endDate: moment(event.end).format('YYYY-MM-DD') || prevFormData.endDate
          }))
        }
        setShowBtnLoading(false)
      } catch (error) {
        console.error('Error analyzing text:', error)
        setShowBtnLoading(false)
      }
    }
  }

  const handleSaveFlyer = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch(selectedFile)
      const blob = await response.blob()
      const blobUrl = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = blobUrl
      link.target = '_blank'
      link.download = `flyer-${moment().format('YYYYMMDDHHmmss')}.png`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(blobUrl)
    } catch (error) {
      console.log('🚀 ~ handleSaveFlyer ~ error:', error)
      console.error('Error downloading image:', error)
    }
  }

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <div id="event-create" className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <Header />
          <SubscriptionLimitInfo subscription={subscriptionData} />
          <>
            <div className="self-stretch text-[#04070A] font-roboto text-[20px] normal-case font-medium leading-[31px] tracking[-0.2px]">
              Create Your Event
            </div>
            <span className="text-gray-600 font-roboto text-sm normal-font font-normal leading-[155%]">
              All created events will be private. Only people you share it with can see it.{' '}
            </span>
            <div className="row mt-3">
              <div className="col mt-3">
                <button
                  type="button"
                  disabled={createMode === 'image-to-text' || imageLoading || loading}
                  onClick={() => setCreateMode('image-to-text')}
                  className="px-5 py-3 text-base font-medium text-center text-white bg-purple-700 rounded-sm hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800 w-full disabled:opacity-70 disabled:cursor-not-allowed"
                >
                  <i className="fa-solid fa-cloud-arrow-up"></i> &nbsp;Upload your flyer
                </button>
              </div>
            </div>
            <div className="row mt-5 mb-3">
              <div className="col">
                <button
                  type="button"
                  disabled={createMode === 'text-to-image' || imageLoading || loading}
                  onClick={() => {
                    setCreateMode('text-to-image')
                    setImageURL('')
                  }}
                  className="px-5 py-3 text-base font-medium text-center text-white bg-purple-700 rounded-sm hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800 w-full disabled:opacity-70 disabled:cursor-not-allowed"
                >
                  <i className="fa-solid fa-hexagon-nodes"></i> &nbsp;Create with AI
                </button>
              </div>
            </div>
          </>

          {createMode === 'image-to-text' && (
            <div className="row mt-3 mb-5">
              <div className="col">
                <div>
                  <FileUploader
                    selectedName={selectedName}
                    onFileSelect={handleFileChange}
                    imageLoading={imageLoading}
                    imageURL={imageURL}
                  />
                </div>
              </div>
            </div>
          )}

          {(createMode === 'text-to-image' || analyzeStatus) && (
            <main>
              <span className="text-gray-600 font-roboto text-sm normal-font font-normal leading-[155%]">
                Tell us about your event. Make sure to include details like the venue name, address, start and end
                times. The better you describe it the better your flyer will be.
              </span>

              <p></p>
              <form onSubmit={handleSubmit}>
                <div className="mt-3 mb-3">
                  <div className="overflow-hidden text-[#04070A] text-ellipsis font-roboto text-sm font-semibold leading-normal">
                    Event Title
                  </div>
                  <input
                    type="text"
                    className="overflow-hidden text-gray-500 font-roboto text-sm font-normal leading-normal line-clamp-2 flex w-3/4 h-12 p-3 px-4 justify-between items-center self-stretch rounded-[12px] border border-[#E0E0E0] bg-white"
                    id="name"
                    name="name"
                    value={formData.name}
                    placeholder="Enter event title"
                    onChange={handleChange}
                    required
                    onBlur={handleInputBlur}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="category" className="form-label">
                    Ethnicity
                  </label>
                  <select
                    className="form-select"
                    id="ethnicity"
                    name="ethnicity"
                    value={formData.ethnicity}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                  >
                    {EVENT_ETHNICITY.map((ethnicity) => (
                      <option key={ethnicity.value} value={ethnicity.value}>
                        {ethnicity.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <div className="overflow-hidden text-[#04070A] text-ellipsis font-roboto text-sm font-semibold leading-normal">
                    Event Description
                  </div>
                  <span className="text-gray-600 font-roboto text-sm normal-font font-normal leading-[155%]">
                    Tell people in detail why they should come out. Don't worry, our AI will clean up your grammar.
                  </span>
                  <span className="text-gray-600 font-roboto text-sm normal-font font-normal leading-[155%]">
                    <p>
                      <b>Example:</b> Get ready to dance all night long to all the reggae & dancehall classics. Every
                      saturday night at 10pm Cafe Circa provides a specialty Caribbean food and Cocktail menu that
                      invokes the ultimate island vibes.
                    </p>
                  </span>
                  <Editor ref={quillRef} defaultValue={formData.description} onTextChange={handleTextChange} />
                  {errors.description && <span className="error">{errors.description}</span>}
                  {/* <input classtype="hidden" name="description" value={formData.description} onChange={handleChange} /> */}
                </div>
                {createMode === 'text-to-image' && (
                  <div className="mb-3">
                    <button
                      type="button"
                      className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 w-full disabled:opacity-70 disabled:cursor-not-allowed"
                      id="analyze-button"
                      onClick={analyzeText}
                      disabled={!quillText || !formData.description}
                    >
                      {showBtnLoading && (
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline w-4 h-4 me-3 text-white animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      )}
                      {showBtnLoading ? 'Analyzing...' : 'Generate Event Details'}
                    </button>
                  </div>
                )}
                {(analyzeQuillText || analyzeStatus) && (
                  <>
                    <div className="hidden mb-3">
                      <label htmlFor="category" className="form-label">
                        Category
                      </label>
                      <select
                        className="form-select"
                        id="category"
                        name="category"
                        value={formData.tags && formData.tags.length > 0 ? formData.tags[0] : ''}
                        onChange={handleChange}
                        onBlur={handleInputBlur}
                      >
                        {EVENT_CATEGORIES.map((category) => (
                          <option key={category.value} value={category.value}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                      <div className="form-text text-danger">{errors?.category}</div>
                    </div>
                    {/* <div className="hidden space-x-4">
                      <div>
                        <input
                          type="radio"
                          className="form-check-input"
                          id="typePublic"
                          name="type"
                          value="1"
                          checked={formData.type === '1'}
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="typePublic">
                          &nbsp;Public
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          className="form-check-input"
                          id="typePrivate"
                          name="type"
                          value="0"
                          checked
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="typePrivate">
                          &nbsp;Private
                        </label>
                      </div>
                    </div> */}
                    <div className="mb-3">
                      <label htmlFor="shareable" className="form-label">
                        Shareable
                      </label>
                      <p className="mt-1 mb-2">Do you want to share this event with others?</p>
                      <div className="flex space-x-4 text-sm">
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="share"
                            name="shareable"
                            value="Y"
                            checked={formData.shareable === 'Y'}
                            onChange={handleChange}
                            onBlur={handleInputBlur}
                            required
                          />
                          <label className="form-check-label" htmlFor="share">
                            Share
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="notShareable"
                            name="shareable"
                            value="N"
                            checked={formData.shareable === 'N'}
                            onChange={handleChange}
                            onBlur={handleInputBlur}
                            required
                          />
                          <label className="form-check-label" htmlFor="notShareable">
                            Not Share
                          </label>
                        </div>
                        <div className="form-text text-danger">{errors?.shareable}</div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="tags" className="form-label">
                        Tags
                      </label>
                      <p className="mt-1 mb-2">Help people find your event by adding tags.</p>
                      <input
                        type="text"
                        className="form-control"
                        id="tags"
                        name="tags"
                        value={formData.tags}
                        placeholder="Add tags"
                        onChange={handleChange}
                        onBlur={handleInputBlur}
                        required
                      />
                      <div className="form-text text-danger">{errors?.tags}</div>
                    </div>
                    {createMode === 'text-to-image' && (
                      <div className="mb-3">
                        <label htmlFor="model" className="form-label">
                          Model
                        </label>
                        <p className="mt-1 mb-2">Who do you want to be featured in your autogenerated flyer</p>
                        <div className="flex space-x-4 text-sm">
                          {EVENT_MODELS.map((model) => (
                            <div className="form-check">
                              <input
                                type="radio"
                                className="form-check-input"
                                id={model.id}
                                name={model.name}
                                value={model.value}
                                checked={formData.model === model.value}
                                onChange={handleChange}
                                onBlur={handleInputBlur}
                                required
                              />
                              <label className="form-check-label" htmlFor={model.id}>
                                {model.label}
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="form-text text-danger">{errors?.model}</div>
                      </div>
                    )}
                    <div className="mt-3 mb-3">
                      <label htmlFor="ticketLink" className="form-label">
                        Ticket Link
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ticketLink"
                        name="ticketLink"
                        value={formData.ticketLink}
                        placeholder="Add ticket link"
                        onChange={handleChange}
                        onBlur={handleInputBlur}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="venueLocation" className="form-label d-none">
                        Venue
                      </label>
                      {address && <Map address={address} />}
                    </div>
                    <div className="mb-3">
                      <AddressAutocomplete onPlaceSelected={handleVenueSelected} value={formData.venueName} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="street" className="form-label">
                        Street
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="street"
                        name="street"
                        value={formData.street}
                        placeholder="Street"
                        onChange={handleChange}
                        onBlur={handleInputBlur}
                        required
                      />
                      <div className="form-text text-danger">{errors?.street}</div>
                    </div>
                    <div className="mb-3 row">
                      <div className="col">
                        <label htmlFor="city" className="form-label">
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          name="city"
                          value={formData.city}
                          placeholder="City"
                          onChange={handleChange}
                          onBlur={handleInputBlur}
                          required
                        />
                        <div className="form-text text-danger">{errors?.city}</div>
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <div className=" hidden col">
                        <label htmlFor="state" className="form-label">
                          State/Province
                        </label>
                        <select
                          className="form-select"
                          id="state"
                          name="state"
                          value={formData.state}
                          onChange={handleChange}
                          onBlur={handleInputBlur}
                        >
                          {EVENT_STATES.map((state) => (
                            <option key={state.value} value={state.value}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                        <div className="form-text text-danger">{errors?.state}</div>
                      </div>
                      <div className=" hidden col">
                        <label htmlFor="zip" className="form-label">
                          Postal Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="zip"
                          name="zip"
                          value={formData.zip}
                          placeholder="Postal Code*"
                          onChange={handleChange}
                          onBlur={handleInputBlur}
                        />
                        <div className="form-text text-danger">{errors?.zip}</div>
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <div className="col">
                        <label htmlFor="startDate" className="form-label">
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="startDate"
                          name="startDate"
                          value={formData.startDate}
                          onChange={handleChange}
                          onBlur={handleInputBlur}
                          placeholder="MM/DD/YYYY"
                          aria-describedby="validationStartDateFeedback"
                          required
                        />
                        <div id="validationStartDateFeedback" className="text-danger">
                          {errors?.startDate}
                        </div>
                      </div>
                      <div className="col">
                        <label htmlFor="startDate" className="form-label">
                          End Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="endDate"
                          name="endDate"
                          value={formData.endDate}
                          onChange={handleChange}
                          onBlur={handleInputBlur}
                          placeholder="MM/DD/YYYY"
                          required
                          aria-describedby="validationEndDateFeedback"
                          disabled={!formData?.startDate}
                        />
                        <div id="validationEndDateFeedback" className="text-danger">
                          {errors?.endDate}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <div className="col">
                        <label htmlFor="start" className="form-label">
                          Start Time
                        </label>
                        <select
                          className="form-control"
                          id="start"
                          name="start"
                          value={formData.start}
                          onChange={handleChange}
                          onBlur={handleInputBlur}
                          required
                          aria-describedby="validationStartFeedback"
                          disabled={!formData?.endDate}
                        >
                          <option value="">Select Time</option>
                          {timeOptions.map((time) => (
                            <option key={time} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                        <div id="validationStartFeedback" className="text-danger">
                          {errors?.startTime}
                        </div>
                      </div>
                      <div className="col">
                        <label htmlFor="end" className="form-label">
                          End Time
                        </label>
                        <select
                          className="form-control"
                          id="end"
                          name="end"
                          value={formData.end}
                          onChange={handleChange}
                          onBlur={handleInputBlur}
                          required
                          aria-describedby="validationEndFeedback"
                          disabled={!formData?.start}
                        >
                          <option value="">Select Time</option>
                          {timeOptions.map((time) => (
                            <option key={time} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                        <div id="validationStartFeedback" className="text-danger">
                          {errors?.endTime}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {createMode !== 'image-to-text' && (analyzeQuillText || analyzeStatus) && (
                  <button
                    type="button"
                    className="btn"
                    id="flyer-button"
                    style={{ marginTop: '20px' }}
                    onClick={generateFlyer}
                    disabled={disableButton}
                  >
                    Generate Image
                  </button>
                )}

                {createMode === 'text-to-image' && images && images.length > 0 && (
                  <>
                    <FlyerThumbnails
                      images={images}
                      handleFileChange={handleFileChange}
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                    />
                    {selectedFile && (
                      <button className="btn mt-4" id="save-button" onClick={(e) => handleSaveFlyer(e)}>
                        Save To Photos
                      </button>
                    )}
                  </>
                )}

                {((analyzeQuillText && selectedFile) || analyzeStatus) && (
                  <button type="submit" className="btn mt-4" id="continue" disabled={disableButton}>
                    Share Your Event With Friends
                  </button>
                )}
              </form>
            </main>
          )}
        </div>
      </div>
    </div>
  )
}

const FileUploader = ({ onFileSelect, selectedName, imageLoading, imageURL }) => {
  const [dragOver, setDragOver] = useState(false)
  const [thumbnail, setThumbnail] = useState(null)

  const [dragOverCounter, setDragOverCounter] = useState(0)
  const fileInputRef = useRef(null)

  const handleDragEnter = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragOverCounter((prev) => prev + 1)
  }, [])

  const handleDragLeave = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      setDragOver(false)

      setDragOverCounter((prev) => prev - 1)
      if (dragOverCounter <= 1) {
        setDragOverCounter(0)
      }
    },
    [dragOverCounter]
  )

  const handleDragOver = useCallback((e) => {
    setDragOver(true)
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      setDragOver(false)
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        onFileSelect(e.dataTransfer.files[0], 'image-to-text')
        generateThumbnail(e.dataTransfer.files[0])
      }
    },
    [onFileSelect]
  )

  const handleChange = useCallback(
    (e) => {
      if (e.target.files && e.target.files[0]) {
        onFileSelect(e.target.files[0], 'image-to-text')
        generateThumbnail(e.target.files[0])
      }
    },
    [onFileSelect]
  )

  const triggerFileInputClick = useCallback(() => {
    fileInputRef.current.click()
  }, [])

  const generateThumbnail = (file) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      setThumbnail(e.target.result)
    }
    reader.readAsDataURL(file)
  }

  return (
    <div
      className={`file-upload ${dragOver ? 'drag-over' : ''}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={triggerFileInputClick}
    >
      {imageLoading ? (
        <div>
          <div className="d-flex justify-content-center align-items-center mt-2">
            <div className="spinner-grow" role="status">
              <span className="visually-hidden">{imageLoading}</span>
            </div>
          </div>
          <div className="uploading-text">{imageLoading}</div>
        </div>
      ) : (
        <>
          {thumbnail ? (
            <img src={thumbnail} alt="thumbnail" className="thumbnail" />
          ) : (
            <img src="/assets/images/icons/file.svg" alt="upload" />
          )}
          <h3> {selectedName || 'Drag and drop a file or click here to select a file'}</h3>
          <p className="mt-3">JPEG or PNG, no larger than 10MB. Recommended size is 500x500.</p>
        </>
      )}

      <input type="file" ref={fileInputRef} onChange={handleChange} style={{ display: 'none' }} />
    </div>
  )
}
export default EventCreate
