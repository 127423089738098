import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const ProfileCard = ({ profile }) => {
  const navigate = useNavigate()
  const [showFullText, setShowFullText] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isMobileOrTablet = windowWidth <= 768
  const isDesktop = windowWidth > 768
  const MAX_LENGTH = isMobileOrTablet ? 20 : isDesktop ? 55 : 33

  if (!profile) return null

  return (
    <div
      className="sm:col-span-full"
      onClick={() => {
        navigate(`/profile/${profile?.user_id}`)
      }}
    >
      <div className="p-6 rounded-lg shadow-md bg-white">
        <div className="sm:col-span-full">
          <div className="flex">
            <div className="h-36 w-36 flex-none">
              <img
                src={profile?.profile_picture_url || '/assets/images/default_pfp.png'}
                alt="Profile"
                onError={(e) => {
                  e.target.onerror = null
                  e.target.src = '/assets/images/default_pfp.png'
                }}
                className="h-full w-full object-cover rounded-full"
              />
            </div>
            <div className="mx-4">
              <h3 className={`mt-4 ${isMobileOrTablet ? 'text-base' : 'text-xl'} font-bold leading-6 text-gray-900`}>
                {profile?.username}
              </h3>
              <p className="mt-8 max-w-2xl text-base text-gray-800">
                {showFullText || !profile?.vibe_title
                  ? profile?.vibe_title || `See what ${profile?.username} is vibing to.`
                  : `${profile?.vibe_title.slice(0, MAX_LENGTH)}...`}
              </p>
              {profile?.vibe_title && profile?.vibe_title.length > MAX_LENGTH && (
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    setShowFullText(!showFullText)
                  }}
                  className="text-indigo-600 hover:underline mt-2 text-sm"
                >
                  {showFullText ? 'Show Less' : 'Show More'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
