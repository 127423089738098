/**
 * Stripe publishable key for frontend integration
 */
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY

/**
 * Application-wide constants
 */

// Plan-related constants
export const SUBSCRIPTION_PLANS = {
  LEVEL_1: {
    id: 1,
    name: 'Level 1',
    monthlyCost: 10,
    annualCost: 100,
    features: ['Create up to 10 Events/months', 'AI Flyer creation', 'Smart analytics', '30-day free trial']
  },
  LEVEL_2: {
    id: 2,
    name: 'Level 2',
    monthlyCost: 35,
    annualCost: 350,
    features: [
      'Create up to 100 Events/months',
      'AI Flyer Creation',
      'Smart analytics',
      'Event Booster',
      '30-day free trial'
    ]
  }
}

// Payment methods
export const PAYMENT_METHODS = {
  CREDIT_CARD: 'credit_card',
  GOOGLE_PAY: 'google_pay',
  APPLE_PAY: 'apple_pay',
  PAYPAL: 'paypal'
}

// Subscription statuses
export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  CANCELLED: 'cancelled',
  PAST_DUE: 'past_due',
  TRIALING: 'trialing'
}

// Theme colors
export const COLORS = {
  PRIMARY: '#6366F1',
  PRIMARY_HOVER: '#5558E6',
  PAYPAL: '#0070BA',
  PAYPAL_HOVER: '#003087',
  SUCCESS: '#10B981',
  WARNING: '#F59E0B',
  DANGER: '#EF4444',
  SELECTED: 'bg-pink-400 text-white',
  UNSELECTED: 'bg-gray-50 text-gray-700 hover:bg-gray-100'
}
