import axios from 'axios'
import Cookies from 'js-cookie'
import config from '../config/config'
/**
 * Default subscription plans configuration
 */
export const defaultSubscriptionPlans = []

/**
 * Get all available subscription plans
 */
export const getSubscriptionPlans = async () => {
  try {
    const response = await axios.get(`${config.BASE_API_URL}/stripe/plans`)

    if (response.data.success && response.data.plans && response.data.plans.length > 0) {
      return { success: true, plans: response.data.plans }
    }

    // Fallback to default plans if API returns empty array
    return { success: true, plans: defaultSubscriptionPlans }
  } catch (error) {
    console.error('Error fetching subscription plans:', error)
    // Fallback to default plans if API fails
    return { success: true, plans: defaultSubscriptionPlans }
  }
}

/**
 * Create a payment intent for subscription
 */
export const createCheckoutSession = async (userId, planLevel, priceId) => {
  try {
    const response = await axios.post(
      `${config.BASE_API_URL}/stripe/create-checkout-session`,
      {
        userId,
        planLevel,
        priceId
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('gn_userToken')}`
        }
      }
    )
    return response.data
  } catch (error) {
    console.error('Error creating payment intent:', error)
    throw error
  }
}

/**
 * Get subscription status for a user
 */
export const getUserSubscriptionStatus = async (userId) => {
  try {
    const response = await axios.get(`${config.BASE_API_URL}/stripe/subscription-status/${userId}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get('gn_userToken')}`
      }
    })
    return response.data
  } catch (error) {
    console.error('Error fetching subscription status:', error)
    throw error
  }
}

/**
 * Update subscription plan details
 */
export const updateSubscriptionPlan = async (planId, updates) => {
  try {
    const response = await axios.put(`${config.BASE_API_URL}/stripe/plans/${planId}`, updates, {
      headers: {
        Authorization: `Bearer ${Cookies.get('gn_userToken')}`
      }
    })
    return response.data
  } catch (error) {
    console.error('Error updating subscription plan:', error)
    throw error
  }
}

/**
 * Upgrade or downgrade a user's subscription
 */
export const changeSubscription = async (userId, newPlanLevel, priceId) => {
  try {
    const response = await axios.post(
      `${config.BASE_API_URL}/stripe/update-subscription`,
      {
        userId,
        newPlanLevel,
        priceId
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('gn_userToken')}`
        }
      }
    )
    return response.data
  } catch (error) {
    console.error('Error changing subscription:', error)
    throw error
  }
}

/**
 * Cancel a subscription at the end of the current billing period
 */
export const cancelSubscription = async (userId) => {
  try {
    const response = await axios.post(
      `${config.BASE_API_URL}/stripe/cancel-subscription`,
      {
        userId
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('gn_userToken')}`
        }
      }
    )
    return response.data
  } catch (error) {
    console.error('Error canceling subscription:', error)
    throw error
  }
}

/**
 * Reactivate a subscription that was scheduled for cancellation
 */
export const reactivateSubscription = async (userId) => {
  try {
    const response = await axios.post(
      `${config.BASE_API_URL}/stripe/reactivate-subscription`,
      {
        userId
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get('gn_userToken')}`
        }
      }
    )
    return response.data
  } catch (error) {
    console.error('Error reactivating subscription:', error)
    throw error
  }
}
