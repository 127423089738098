import React, { useEffect, useState, useRef } from 'react'
import { useLocation, Link } from 'react-router-dom'
import moment from 'moment'
import { getUpcomingEvents } from '../../../services/eventService'
import AdComponent from '../ads'
import Header from '../../common/header/Header'
import { LoadingIndicator } from '../../common/loading'
import { EventCard } from '../card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSearch, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { classNames } from '../../../utils'
import { getAllProfiles } from '../../../services/userService'
import { ProfileCard } from '../../vibe/card/ProfileCard'

const TagsGroup = [
  { name: 'Trending', value: 'trending' },
  { name: 'Today', value: 'dayOfWeek' },
  { name: 'For You', value: 'for-you' },
  { name: 'All', value: 'all' }
]

// Function to get tag suggestions from LLM
const getTagSuggestions = async (searchTerm, allTags) => {
  try {
    const token = localStorage.getItem('token')

    const response = await fetch('/api/tag-suggestions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token
      },
      body: JSON.stringify({
        searchTerm,
        availableTags: allTags
      })
    })

    if (!response.ok) {
      throw new Error('Failed to get tag suggestions')
    }

    const data = await response.json()
    return data.success ? data.suggestions : []
  } catch (error) {
    console.error('Error getting tag suggestions:', error)

    // Fallback: simple text matching if API fails
    return allTags.filter((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()))
  }
}

function Events() {
  const location = useLocation()
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))
  const scrollToEventId = location.state?.scrollToEventId
  // const navigate = useNavigate()

  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)

  const [selectedTagGroup, setSelectedTagGroup] = useState(null)
  const [allTags, setAllTags] = useState([])
  const [tags, setTags] = useState([])
  const [selectedTag, setSelectedTag] = useState(null)
  const [filteredEvents, setFilteredEvents] = useState([])
  const [myTags, setMyTags] = useState([])

  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const searchInputRef = useRef(null)
  const suggestionsRef = useRef(null)

  const tagsContainerRef = useRef(null)
  const [showRightIndicator, setShowRightIndicator] = useState(true)
  const [showLeftIndicator, setShowLeftIndicator] = useState(false)

  const [profiles, setProfiles] = useState([])
  const [profilesLoading, setProfilesLoading] = useState(false)

  // Create refs for events
  const eventRefs = useRef({})

  // Check if scrolling is needed and update indicators
  const checkScrollIndicators = () => {
    if (tagsContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tagsContainerRef.current
      // Show right indicator if there's more content to scroll right
      setShowRightIndicator(scrollLeft < scrollWidth - clientWidth - 10)
      // Show left indicator if we've scrolled to the right
      setShowLeftIndicator(scrollLeft > 10)
    }
  }

  // Add scroll event listener
  useEffect(() => {
    const container = tagsContainerRef.current
    if (container) {
      container.addEventListener('scroll', checkScrollIndicators)
      // Initial check
      checkScrollIndicators()

      return () => {
        container.removeEventListener('scroll', checkScrollIndicators)
      }
    }
  }, [tags])

  useEffect(() => {
    const getEvents = async () => {
      const response = await getUpcomingEvents(user?.userId)
      if (response.success) {
        const currentTime = moment()
        const combinedEvents = response.data.events
          .filter((event) => {
            // Filter out events that have already ended
            return moment(event.end).isAfter(currentTime)
          })
          .map((event) => {
            return {
              ...event,
              tags: event.tags.split(',').map((tag) => tag.trim()),
              dayOfWeek: moment(event.start).format('ddd')
            }
          })
        setEvents(combinedEvents)
        setMyTags(combinedEvents?.[0]?.User?.Profile?.interests?.split(',')?.map((tag) => tag.trim()) ?? [])
        if (Array.isArray(response.data.tags)) {
          const tagFrequency = {}
          response.data.tags.forEach((tag) => {
            if (tagFrequency[tag]) {
              tagFrequency[tag]++
            } else {
              tagFrequency[tag] = 1
            }
          })
          const sortedTags = Object.keys(tagFrequency).sort((a, b) => tagFrequency[b] - tagFrequency[a])
          setAllTags(sortedTags)
          setSelectedTagGroup(TagsGroup[3])
        }
        setLoading(false)
      } else {
        setLoading(false)
      }
    }
    getEvents()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (selectedTagGroup) {
      const type = selectedTagGroup.value
      switch (type) {
        case 'trending':
          setTags(allTags.slice(0, 10))
          filterEventsByTag({ type: type, value: allTags?.[0] ?? null })
          break
        case 'dayOfWeek':
          const dayOfWeekToday = moment().format('ddd')
          setTags(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'])
          setSelectedTag(dayOfWeekToday)
          filterEventsByTag({ type: type, value: dayOfWeekToday })
          break
        case 'for-you':
          setTags(myTags?.slice(0, 4) ?? [])
          filterEventsByTag({ type: type, value: myTags?.[0] ?? null })
          break
        case 'all':
          setTags(allTags)
          filterEventsByTag({ type: type, value: null })
          break
        default:
          break
      }
    }
    // eslint-disable-next-line
  }, [selectedTagGroup])

  // Handle search input changes with debounce
  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (searchTerm.trim().length > 0) {
        setIsSearching(true)
        const suggestions = await getTagSuggestions(searchTerm, allTags)
        setSearchResults(suggestions)
        setIsSearching(false)
        setShowSuggestions(true)
      } else {
        setSearchResults([])
        setShowSuggestions(false)
      }
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm, allTags])

  // Close suggestions when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target) &&
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target)
      ) {
        setShowSuggestions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleSearchSubmit = async (e) => {
    e.preventDefault()
    if (searchTerm.trim()) {
      setIsSearching(true)
      const suggestions = await getTagSuggestions(searchTerm, allTags)

      if (suggestions.length > 0) {
        // If the exact search term exists as a tag, use it
        if (suggestions.includes(searchTerm)) {
          filterEventsByTag({ type: 'search', value: searchTerm })
        } else {
          // Otherwise use the first suggestion
          filterEventsByTag({ type: 'search', value: suggestions[0] })
        }
      } else {
        // No matches found
        setFilteredEvents([])
      }

      setIsSearching(false)
      setShowSuggestions(false)
    }
  }

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion)
    filterEventsByTag({ type: 'search', value: suggestion })
    setShowSuggestions(false)
  }

  const filterEventsByTag = ({ type, value }) => {
    setSelectedTag(value)
    if (!value) {
      setFilteredEvents(events)
      setSelectedTag(null)
      return
    }
    switch (type) {
      case 'trending':
        setFilteredEvents(events.filter((event) => event.tags.includes(value)))
        break
      case 'dayOfWeek':
        setFilteredEvents(events.filter((event) => moment(event.start).format('ddd') === value))
        break
      case 'for-you':
        setFilteredEvents(events.filter((event) => event.tags.includes(value)))
        break
      case 'all':
        setFilteredEvents(events.filter((event) => event.tags.includes(value)))
        break
      case 'search':
        setFilteredEvents(events.filter((event) => event.tags.includes(value)))
        // Update the selected tag group to 'All' when searching
        setSelectedTagGroup(TagsGroup[3])
        break
      default:
        break
    }
  }

  // Function to scroll the container
  const scrollTags = (direction) => {
    if (tagsContainerRef.current) {
      const scrollAmount = 200 // Adjust as needed
      const currentScroll = tagsContainerRef.current.scrollLeft

      tagsContainerRef.current.scrollTo({
        left: direction === 'left' ? currentScroll - scrollAmount : currentScroll + scrollAmount,
        behavior: 'smooth'
      })
    }
  }

  // Update current page on scroll
  const handleTagsScroll = () => {
    if (tagsContainerRef.current) {
      // const { scrollLeft, clientWidth } = tagsContainerRef.current
      // const page = Math.round(scrollLeft / clientWidth);
      // setCurrentPage(page);
      checkScrollIndicators()
    }
  }

  // Scroll to the event after render if needed
  useEffect(() => {
    if (scrollToEventId && eventRefs.current[scrollToEventId]) {
      // Scroll the event into view with a small delay to ensure rendering is complete
      setTimeout(() => {
        eventRefs.current[scrollToEventId].scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }, 100)
    }
  }, [scrollToEventId, filteredEvents])

  useEffect(() => {
    const fetchProfileData = async () => {
      setProfilesLoading(true)
      try {
        const profilesResponse = await getAllProfiles()
        if (profilesResponse.success) {
          setProfiles(profilesResponse.data.data)
        } else {
          setProfiles([])
        }
      } catch (error) {
        console.error('Error fetching all the profiles: ', error)
        setProfiles([])
      } finally {
        setProfilesLoading(false)
      }
    }
    fetchProfileData()
  }, [])

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <div id="events" className="min-h-screen max-w-full overflow-x-hidden">
      <Header />
      <main className="px-4 w-full max-w-full">
        <div className="mb-3 text-[#515151] text-sm font-normal font-roboto leading-snug">
          If you know what you're looking for, search below, or just slide the tags to the right to find your vibe.
        </div>
        <div className="w-full">
          {user && (!user.about_me || !user.interests) && (
            <div className="bg-purple-50 p-3 rounded-md mb-4">
              <p className="text-sm text-purple-800">
                Complete your profile to get personalized event recommendations
                <Link to="/promoter/profile" className="ml-2 underline font-medium">
                  Complete now
                </Link>
              </p>
            </div>
          )}
          <div className="max-w-screen-sm mx-auto w-full">
            {/* Search component */}
            <div className="mb-4 w-full">
              <form onSubmit={handleSearchSubmit} className="relative">
                <div className="relative">
                  <input
                    ref={searchInputRef}
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search for events by hashtag..."
                    className="w-full px-4 py-2 pr-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500"
                    onFocus={() => searchTerm.trim().length > 0 && setShowSuggestions(true)}
                  />
                  <button
                    type="submit"
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-violet-600"
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>

                {/* Autocomplete suggestions */}
                {showSuggestions && searchResults.length > 0 && (
                  <div
                    ref={suggestionsRef}
                    className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto"
                  >
                    {isSearching ? (
                      <div className="p-2 text-center text-gray-500">Searching...</div>
                    ) : (
                      <ul>
                        {searchResults.map((suggestion, index) => (
                          <li
                            key={index}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            {suggestion}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </form>
            </div>

            {tags.length > 0 && (
              <div className="tag-btn-container w-full">
                {/* Secondary tags container */}
                <div className="w-full overflow-hidden relative">
                  {/* Left scroll button */}
                  {showLeftIndicator && (
                    <button
                      onClick={() => scrollTags('left')}
                      className="absolute left-0 top-[calc(50%-3px)] transform -translate-y-1/2 z-20 bg-white bg-opacity-70 rounded-full shadow-sm p-0.5 hover:bg-gray-100 text-xs"
                      aria-label="Scroll left"
                    >
                      <FontAwesomeIcon icon={faChevronLeft} className="text-gray-400 w-3 h-3" />
                    </button>
                  )}

                  {/* Right scroll button */}
                  {showRightIndicator && (
                    <button
                      onClick={() => scrollTags('right')}
                      className="absolute right-[-3px] top-[calc(50%-3px)] transform -translate-y-1/2 z-20 bg-white bg-opacity-70 rounded-full shadow-sm p-0.5 hover:bg-gray-100 text-xs"
                      aria-label="Scroll right"
                    >
                      <FontAwesomeIcon icon={faChevronRight} className="text-gray-400 w-3 h-3" />
                    </button>
                  )}

                  {/* Left fade indicator */}
                  {showLeftIndicator && (
                    <div
                      className="absolute left-0 top-0 bottom-0 w-8 z-10 pointer-events-none"
                      style={{
                        background: 'linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)'
                      }}
                    />
                  )}

                  {/* Right fade indicator */}
                  {showRightIndicator && (
                    <div
                      className="absolute right-0 top-0 bottom-0 w-8 z-10 pointer-events-none"
                      style={{
                        background: 'linear-gradient(to left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)'
                      }}
                    />
                  )}

                  <div
                    ref={tagsContainerRef}
                    className="overflow-x-auto scrollbar-hide -mx-4 px-4"
                    onScroll={handleTagsScroll}
                  >
                    <div className="flex gap-2 pb-2 w-max">
                      {tags.length > 0 &&
                        tags?.map((tag, index) => (
                          <button
                            key={`tag-${index}`}
                            type="button"
                            onClick={() => {
                              filterEventsByTag({ type: selectedTagGroup.value, value: tag })
                            }}
                            className={classNames(
                              'h-6 px-3 py-2 text-sm rounded-[40px] border border-[#e0e0e0] justify-center items-center gap-1 inline-flex',
                              'transition-colors duration-200 ease-in-out',
                              selectedTag === tag
                                ? 'bg-purple-500 hover:bg-purple-600 text-white'
                                : 'bg-[#f3f4f6] hover:bg-gray-200 text-gray-800'
                            )}
                          >
                            <span>{tag}</span>
                          </button>
                        ))}
                    </div>
                  </div>

                  {selectedTag && (
                    <button
                      id="clear_tag"
                      className="text-sm my-2 px-3 py-1 hover:bg-gray-100 rounded-md transition-colors duration-200"
                      onClick={() => filterEventsByTag({ type: selectedTagGroup, value: null })}
                    >
                      <FontAwesomeIcon icon={faTimes} /> Clear Filter
                    </button>
                  )}
                </div>
              </div>
            )}

            {/* Events content */}
            <div>
              {filteredEvents.map((event, index) => (
                <div key={event.id || index} className="w-full" ref={(el) => (eventRefs.current[event.id] = el)}>
                  <div className="event-card-container">
                    <EventCard event={event} />
                  </div>
                  {(index + 1) % 5 === 1 && <AdComponent index={index} />}
                  {!profilesLoading && (index + 1) % 3 === 0 && profiles.length > 0 && (
                    <ProfileCard profile={profiles[Math.floor(Math.random() * profiles.length)]} />
                  )}
                </div>
              ))}

              {filteredEvents.length === 0 && (
                <div className="flex justify-center items-center min-h-[50vh] w-full">
                  <p className="text-center">
                    There are no events based on the filters you selected, please select another.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Events
