import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getActivities, getPublicUserInfo, getFollwersOfUser } from '../services/userService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage } from '@fortawesome/free-regular-svg-icons'
import { joinToPrivateChatRoom } from '../services/chatService'
import useMessageStore from '../store/messageStore'
import { LoadingIndicator } from '../components/common/loading'
import { classNames } from '../utils'

export default function ProfileCard() {
  const location = useLocation()
  const navigate = useNavigate()
  const _userInfo = localStorage.getItem('user')
  const userInfo = _userInfo ? JSON.parse(_userInfo) : null
  const myUserId = userInfo?.userId
  const [activities, setActivities] = useState([])
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))
  const [profileInfo, setProfileInfo] = useState()
  const [userFollowers, setUserFollowers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const addRoomIdToMessages = useMessageStore((state) => state.addRoomIdToMessages)

  const params = useParams()
  const { userId } = params

  const handleJoinPrivateChat = async () => {
    const myUserId = user?.userId
    if (!userId || !myUserId) {
      navigate('/login')
      return
    }
    const payload = {
      userId: myUserId,
      friendId: userId
    }
    const res = await joinToPrivateChatRoom(payload)
    if (res?.success) {
      addRoomIdToMessages(res.roomId)
      navigate(`/chatroom/${res.roomId}`)
    }
  }

  const fetchUserActivities = async () => {
    const data = await getActivities(userId)
    if (data?.success) {
      setActivities(data.data)
    }
  }

  const fetchPublicUserInfo = async () => {
    setIsLoading(true)
    const res = await getPublicUserInfo(userId)
    if (res?.success) {
      setProfileInfo(res.data?.data)
    }
    setIsLoading(false)
  }

  const fetchUserFollowers = async () => {
    const data = await getFollwersOfUser(userId)
    if (data.success) {
      setUserFollowers(data.data)
    }
  }

  useEffect(() => {
    if (userId) {
      fetchPublicUserInfo()
      fetchUserActivities()
      fetchUserFollowers()
    }
    // eslint-disable-next-line
  }, [userId])

  // eslint-disable-next-line
  const onAddFriend = async () => {
    if (!userId || !myUserId) {
      navigate('/login')
      return
    }
  }

  if (isLoading || !profileInfo) {
    return <LoadingIndicator />
  }

  return (
    <div className="max-w-2xl md:w-1/2 bg-white rounded-xl shadow-md p-6 mx-auto">
      <div className="flex items-center space-x-4 mb-3">
        <img
          src={profileInfo?.profile_picture_url || '/assets/images/default_pfp.png'}
          alt="Profile"
          className="w-32 h-32 object-cover rounded-full"
        />
        <div>
          <div className="flex">
            <h2 className="text-2xl font-bold">{profileInfo.username}</h2>
            {userId !== myUserId && (
              <Link
                onClick={() => handleJoinPrivateChat()}
                className="mx-6 p-2 rounded-[80px] justify-center items-center gap-2 inline-flex text-sm text-white"
                id="share-event"
              >
                <FontAwesomeIcon className="size-7" icon={faMessage} color="black" />
              </Link>
            )}
          </div>
          <p className="text-gray-500 text-sm">{profileInfo?.about_me}</p>
          <span className="mt-6 bg-purple-100 text-purple-600 text-sm font-semibold px-2 py-1 rounded-lg">
            {userInfo?.status || 'Regular'}
          </span>
        </div>
        {/* <button className="ml-auto bg-blue-500 text-white px-4 py-2 rounded-lg text-sm font-semibold" onClick={onAddFriend}>Friend</button> */}
      </div>
      {parseInt(userId) === myUserId && (
        <button
          className="text-white px-4 py-2 mt-5 rounded-none gap-6 bg-[#8023eb] hover:bg-[#8023eb] w-full"
          onClick={(e) => navigate('/promoter/profile')}
        >
          Edit Profile
        </button>
      )}

      <div className="space-y-8">
        {profileInfo?.vibe_title && (
          <div className="bg-white p-6 rounded-lg shadow-md mt-4">
            <h3 className="text-lg font-semibold">My Vibe</h3>
            <p className="text-gray-600">{profileInfo?.vibe_title}</p>
          </div>
        )}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold">Tags I Follow</h3>
          <div className="flex flex-wrap my-2">
            {profileInfo?.tags?.map((tag, index) => (
              <span
                className={classNames(
                  'mr-2 my-1 bg-purple-200/40 text-sm px-3 py-1 rounded-sm',
                  index % 9 === 0
                    ? 'bg-purple-200/40 text-purple-800'
                    : index % 9 === 1
                      ? 'bg-blue-200/40 text-blue-800'
                      : index % 9 === 2
                        ? 'bg-green-200/40 text-green-800'
                        : index % 9 === 3
                          ? 'bg-red-200/40 text-red-800'
                          : index % 9 === 4
                            ? 'bg-pink-200/40 text-pink-800'
                            : index % 9 === 5
                              ? 'bg-yellow-200/40 text-yellow-800'
                              : index % 9 === 6
                                ? 'bg-orange-200/40 text-orange-800'
                                : index % 9 === 7
                                  ? 'bg-teal-200/40 text-teal-800'
                                  : 'bg-indigo-200/40 text-indigo-800'
                )}
              >
                {tag}
              </span>
            ))}
          </div>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold">More About Me</h3>
          <p className="text-gray-600">{profileInfo?.about_me}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold">Events I've Attended ({activities?.length})</h3>
          <div className="mt-2 text-sm text-gray-500 ">
            <div className="flex flex-nowrap gap-3 overflow-x-auto">
              {activities.map((activity, index) => (
                <div className="rounded-lg shrink-0" key={index}>
                  <img src={activity.flyer} alt={activity.name} className="h-20 w-20 object-cover rounded-xl" />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-medium leading-6 text-gray-900">Followers ({userFollowers?.length})</h2>
          <p className="mt-2 max-w-2xl text-sm text-gray-500">
            <div className="grid grid-cols-4 lg:grid-cols-6 gap-4 mt-2">
              {userFollowers.map((userFollower, index) => (
                <div className="col-span-1 rounded-lg max-w-16" key={index}>
                  <img
                    src={userFollower?.FollowerUser?.Profile?.profile_picture_url || '/assets/images/default_pfp.png'}
                    alt={userFollower?.username}
                    className="h-16 w-16 object-cover rounded-full cursor-pointer"
                    onClick={() => {
                      navigate(`/profile/${userFollower?.FollowerUser?.user_id}`)
                    }}
                  />
                  <div className="relative group">
                    <div className="mt-2 text-center overflow-x-hidden">
                      {userFollower?.FollowerUser?.Profile?.username}
                    </div>
                    <div className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm rounded px-2 py-1 shadow-lg">
                      {userFollower?.FollowerUser?.Profile?.username}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </p>
        </div>
      </div>
    </div>
  )
}
