import api from './api'
import moment from 'moment-timezone'

const createChatRoom = async (formData) => {
  try {
    const response = await api.post('/event', formData)
    return response.data
  } catch (error) {
    throw error
  }
}

const getChatRoomDetails = async (roomId) => {
  try {
    const res = await api.get(`/chats/rooms/${roomId}`)
    return res.data
  } catch (error) {
    return null
  }
}

const fetchMessages = async (roomId) => {
  try {
    const response = await api.get(`/chats/rooms/${roomId}/messages`)
    if (response.data.messages && response.data.messages.length > 0) {
      const messages = response.data.messages.map((message) => {
        const time = moment(message.createdAt).format('hh:mm A')
        return {
          ...message,
          time
        }
      })
      return { success: true, messages: messages }
    }
    return { success: true, messages: [] }
  } catch (error) {
    return { success: false, message: error?.response?.data?.message }
  }
}

const fetchParticipants = async (roomId) => {
  try {
    const response = await api.get(`/chats/rooms/${roomId}/participants`)
    return { success: true, participants: response.data.participants }
  } catch (error) {
    return { success: false, message: error?.response?.data?.message }
  }
}

const createMessage = async (roomId, userId, message) => {
  try {
    const response = await api.post(`/chats/rooms/${roomId}/messages`, {
      message,
      senderId: userId
    })
    return response.data
  } catch (error) {
    throw error
  }
}

const joinToChatRoom = async (payload) => {
  try {
    const response = await api.post(`/chats/rooms/events/${payload.eventId}/join`, payload)
    return response.data
  } catch (error) {
    return null
  }
}
const leaveChatroom = async (roomId, userId) => {
  try {
    const response = await api.post(`/chats/rooms/${roomId}/leave`, { userId })
    return response.data
  } catch (error) {
    return null
  }
}

const reportMessage = async (roomId, messageId) => {
  try {
    const response = await api.post(`/chats/rooms/${roomId}/messages/${messageId}/report`)
    return response.data
  } catch (error) {
    return null
  }
}

const getUnreadStatus = async (userId) => {
  try {
    const response = await api.get(`/chats/status/${userId}`)
    return response.data
  } catch (error) {
    return null
  }
}

const updateReadStatusForRoom = async (roomId) => {
  try {
    const response = await api.post(`/chats/rooms/${roomId}/read`)
    return response.data
  } catch (error) {
    return null
  }
}

const joinToPrivateChatRoom = async (payload) => {
  try {
    const response = await api.post(`/chats/rooms/private/join`, payload)
    return response.data
  } catch (error) {
    return null
  }
}

export {
  createChatRoom,
  getChatRoomDetails,
  fetchMessages,
  createMessage,
  joinToChatRoom,
  fetchParticipants,
  leaveChatroom,
  reportMessage,
  getUnreadStatus,
  updateReadStatusForRoom,
  joinToPrivateChatRoom
}
