import React from 'react'

/**
 * Component to display subscription event limits and current usage
 * @param {Object} subscription - User's subscription data
 * @returns {JSX.Element|null} - Subscription limit info component or null if no subscription
 */
const SubscriptionLimitInfo = ({ subscription }) => {
  if (!subscription || !subscription.planLevel) {
    return null
  }

  const maxEvents = subscription.maxEventsPerMonth || 0
  const eventsCreated = subscription.eventsThisMonth || 0
  const eventsRemaining = Math.max(0, maxEvents - eventsCreated)
  const percentUsed = maxEvents > 0 ? Math.min(100, Math.round((eventsCreated / maxEvents) * 100)) : 0

  // Determine color and status based on usage
  let barColor = 'from-green-400 to-green-500'
  let bgColor = 'bg-green-50'
  let borderColor = 'border-green-200'
  let textColor = 'text-green-700'
  let iconStatus = '👍'

  if (percentUsed > 80) {
    barColor = 'from-red-400 to-red-500'
    bgColor = 'bg-red-50'
    borderColor = 'border-red-200'
    textColor = 'text-red-700'
    iconStatus = '⚠️'
  } else if (percentUsed > 50) {
    barColor = 'from-yellow-300 to-yellow-400'
    bgColor = 'bg-yellow-50'
    borderColor = 'border-yellow-200'
    textColor = 'text-yellow-700'
    iconStatus = '📊'
  }

  return (
    <div
      className={`mb-6 p-5 ${bgColor} rounded-xl shadow-sm border ${borderColor} transition-all duration-300 hover:shadow-md`}
    >
      <div className="flex justify-between items-center mb-3">
        <h3 className="text-lg font-semibold text-gray-800">Subscription Event Limit</h3>
        <div className="text-2xl" role="img" aria-label="status icon">
          {iconStatus}
        </div>
      </div>

      <div className="flex items-center gap-3 mb-4">
        <div className="flex-1">
          <div className="w-full bg-gray-200 rounded-md h-3 overflow-hidden shadow-inner">
            <div
              className={`bg-gradient-to-r ${barColor} h-3 rounded-md transition-all duration-500 ease-in-out`}
              style={{ width: `${percentUsed || 3}%` }}
            ></div>
          </div>
        </div>
        <div className="text-sm font-medium text-gray-700 whitespace-nowrap">{percentUsed}%</div>
      </div>

      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <span className="inline-flex items-center justify-center p-1.5 rounded-lg bg-white shadow-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 text-gray-600"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clipRule="evenodd"
              />
            </svg>
          </span>
          <span className="text-sm font-medium text-gray-700">
            {eventsCreated} of {maxEvents} used
          </span>
        </div>
        <div className={`text-sm font-medium ${textColor} px-3 py-1 rounded-md bg-white shadow-sm`}>
          {eventsRemaining} remaining
        </div>
      </div>

      {percentUsed > 80 && (
        <div className="mt-4 flex items-start gap-2 p-3 bg-white rounded-lg shadow-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-red-500 flex-shrink-0 mt-0.5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p className="text-sm text-gray-700">
            You're approaching your monthly event limit. Consider upgrading your plan for more events.
          </p>
        </div>
      )}
    </div>
  )
}

export default SubscriptionLimitInfo
