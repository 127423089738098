import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Toasts from '../../common/toasts/Toasts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons'
import { LoadingIndicator } from '../../common/loading'

function Register({
  onSubmit,
  invite,
  loading,
  formData,
  handleChange,
  handleInputBlur,
  errors,
  setShowToast,
  showToast,
  toastMessage
}) {
  const navigate = useNavigate()
  const isFormValid = !Object.values(errors).some((error) => error)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setConfirmShowPassword] = useState(false)

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }
  const handleToggleConfirmPassword = () => {
    setConfirmShowPassword((prevShowConfirmPassword) => !prevShowConfirmPassword)
  }

  if (loading) {
    return <LoadingIndicator />
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const result = await onSubmit(formData)
      if (result.success) {
        navigate('/')
      }
    } catch (error) {
      console.error('Registration failed : ', error)
    }
  }

  return (
    <div className="container">
      <div id="register" className="w-full">
        <div className="m-6">
          {' '}
          <a href="/">
            <FontAwesomeIcon icon={faArrowAltCircleLeft} />
          </a>
          <div className="text-center">Register</div>
        </div>
        {/* <Header invite={invite} auth="unauthenticated" showLink={true} /> */}
        <main>
          <form onSubmit={handleSubmit}>
            <div className="m-6">
              <div className="">
                <label className="text-sm text-bold">Your Name</label>

                <input
                  type="text"
                  className="text-sm rounded w-full p-2.5 bg-slate-50"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div className="text-sm text-red-500">{errors?.name}</div>
            </div>
            <div className="m-6">
              <div className="text-sm text-bold">
                <label htmlFor="email" className="text-sm text-bold">
                  Email
                </label>

                {/* <span className="form-icon" id="basic-addon1">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span> */}

                <input
                  type="email"
                  className="text-sm rounded w-full p-2.5 bg-slate-50"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  placeholder="Enter email"
                  required
                />
              </div>
              <div className="text-sm text-red-500">{errors?.email}</div>
            </div>
            <div className="m-6">
              <div className="text-sm text-bold">
                <label htmlFor="cell" className="text-sm text-bold">
                  Phone
                </label>

                {/* <span className="form-icon" id="basic-addon1">
                  <FontAwesomeIcon icon={faPhone} />
                </span> */}

                <input
                  type="text"
                  className="text-sm rounded w-full p-2.5 bg-slate-50"
                  id="cell"
                  name="cell"
                  value={formData.cell}
                  onChange={handleChange}
                  placeholder="Enter phone"
                />
              </div>
              <div className="text-sm text-red-500">{errors?.cell}</div>
            </div>
            <div className="m-6">
              <div className="text-sm text-bold">
                <label htmlFor="cell" className="text-sm text-bold">
                  Password
                </label>

                {/* <span className="form-icon" id="basic-addon1">
                  <FontAwesomeIcon icon={faLock} />
                </span> */}

                <input
                  type={showPassword ? 'text' : 'password'}
                  className="text-sm rounded w-full p-2.5 bg-slate-50"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  placeholder="Enter password"
                  required
                />
                <button type="button" onClick={handleTogglePassword} className="right-4 bottom-3 absolute text-xs">
                  {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </button>
              </div>
              <div className="text-sm text-red-500">{errors?.password}</div>
            </div>
            <div className="m-6">
              <div className="text-sm text-bold">
                <label htmlFor="cell" className="text-sm text-bold">
                  Confirm Password
                </label>

                <span className="form-icon" id="basic-addon1">
                  {/* <FontAwesomeIcon icon={faLock} /> */}
                </span>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  className="text-sm rounded w-full p-2.5 bg-slate-50"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  placeholder="Enter password"
                  required
                />
                <button
                  type="button"
                  onClick={handleToggleConfirmPassword}
                  className="right-4 bottom-3 absolute text-xs"
                >
                  {showConfirmPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </button>
              </div>
              <div className="text-sm text-red-500">{errors?.confirmPassword}</div>
            </div>
            <div className={`mb-4 ${toastMessage?.includes('failed') ? 'text-red-500' : 'text-green-500'}`}>
              <Toasts show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
            </div>
            <button
              type="submit"
              className="button button--secondary text-white w-full mb-5 button_large"
              id="sign-up"
              disabled={!isFormValid}
            >
              Sign Up
            </button>
          </form>
        </main>

        <footer id="footer" className="text-center text-sm">
          <p className="mb-1">
            By signing up you agree to our{' '}
            <Link to="/terms-of-service" className="text-primary">
              Terms and Conditions
            </Link>
          </p>
          <p>
            See how we use your data in our{' '}
            <Link to="/privacy-policy" className="text-primary">
              Privacy Policy
            </Link>
          </p>
        </footer>
      </div>
    </div>
  )
}

export default Register
