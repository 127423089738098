import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { loginUser, validateUser } from '../services/userService'
import { getUserSubscriptionStatus } from '../services/subscriptionService'

const UserContext = createContext()

export const useUser = () => useContext(UserContext)

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [subscription, setSubscription] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()

  // Extract subscription info from user data when available
  const updateSubscriptionFromUserData = (userData) => {
    if (!userData) return null

    if (userData.subscription) {
      console.log('Raw user subscription data:', userData.subscription)

      // If user data includes subscription info, use it
      const subscriptionData = {
        success: true,
        hasActiveSubscription: userData.subscription.status === 'active' || userData.subscription.status === 'trialing',
        planLevel:
          typeof userData.subscription.plan_level === 'string'
            ? parseInt(userData.subscription.plan_level, 10)
            : userData.subscription.plan_level,
        status: userData.subscription.status,
        currentPeriodEnd: userData.subscription.current_period_end,
        isTrial: Boolean(userData.subscription.is_trial) || userData.subscription.status === 'trialing',
        trialEnd: userData.subscription.trial_end || null,
        cancelAtPeriodEnd: Boolean(userData.subscription.cancel_at_period_end)
      }

      console.log('Processed user subscription data:', subscriptionData)

      setSubscription(subscriptionData)
      return true
    }
    return false
  }

  // Fallback to API call if necessary
  const fetchSubscriptionStatus = async (userId) => {
    if (!userId) return null

    try {
      console.log('Fetching subscription status for user:', userId)
      const result = await getUserSubscriptionStatus(userId)
      console.log('Raw API response:', result)

      if (result.success) {
        // Format the result to ensure consistent data types
        const formattedResult = {
          ...result,
          // Ensure boolean values are actual booleans, not string representations
          hasActiveSubscription: Boolean(result.hasActiveSubscription),
          isTrial: Boolean(result.isTrial),
          cancelAtPeriodEnd: Boolean(result.cancelAtPeriodEnd),
          // Ensure trialEnd is always a proper value or null
          trialEnd: result.trialEnd || null,
          // Ensure planLevel is a number
          planLevel: typeof result.planLevel === 'string' ? parseInt(result.planLevel, 10) : result.planLevel
        }

        console.log('Formatted subscription data:', formattedResult)

        // Store the complete subscription data
        setSubscription(formattedResult)
        return formattedResult
      }
    } catch (error) {
      console.error('Error fetching subscription:', error)
    }
    return null
  }

  useEffect(() => {
    const userToken = Cookies.get('gn_userToken')

    const autoLogin = async () => {
      if (userToken) {
        try {
          const result = await validateUser(userToken)

          if (result.success) {
            Cookies.set('gn_userToken', userToken)
            localStorage.setItem('user', JSON.stringify(result.data))
            setUser(result.data)

            // First try to get subscription from user data
            const subscriptionExtracted = updateSubscriptionFromUserData(result.data)

            // Only make API call if subscription wasn't in user data
            if (!subscriptionExtracted && result.data && result.data.userId) {
              await fetchSubscriptionStatus(result.data.userId)
            }
          } else {
            handleLogout()
          }
        } catch (error) {
          console.error('Error auto-login:', error)
          handleLogout()
        } finally {
          setLoading(false)
        }
      } else {
        handleLogout()
        setLoading(false)
      }
    }

    autoLogin()

    const isMainPage = location.pathname === '/'
    const isSharedLink = location.pathname.includes('influencer')
    const isForgotPasswordLink = location.pathname.endsWith('forgot-password')
    const isResetPasswordLink = location.pathname.endsWith('reset-password')
    const isRegistrationLink = location.pathname.endsWith('register')
    const isPrivacyPolicyLink = location.pathname.endsWith('privacy-policy')
    const isTermsOfServiceLink = location.pathname.endsWith('terms-of-service')
    const isScanCallbackLink = location.pathname.includes('scan-qr-callback')
    const isEventDetailPage = location.pathname.includes('promoter/event')
    const isPublicProfilePage = location.pathname.includes('profile')

    if (
      !isMainPage &&
      !isSharedLink &&
      !userToken &&
      !isForgotPasswordLink &&
      !isResetPasswordLink &&
      !isRegistrationLink &&
      !isPrivacyPolicyLink &&
      !isTermsOfServiceLink &&
      !isScanCallbackLink &&
      !isEventDetailPage &&
      !isPublicProfilePage
    ) {
      navigate('/login')
    }
  }, [location.pathname, navigate])

  const login = async (email, password) => {
    try {
      const result = await loginUser(email, password)
      if (result.success) {
        Cookies.set('gn_userToken', result.data.token)
        localStorage.setItem('user', JSON.stringify(result.data))
        setUser(result.data)

        // First try to get subscription from user data
        const subscriptionExtracted = updateSubscriptionFromUserData(result.data)

        // Only make API call if subscription wasn't in user data
        if (!subscriptionExtracted && result.data && result.data.userId) {
          await fetchSubscriptionStatus(result.data.userId)
        }

        return { success: true }
      } else {
        return { success: false, message: result.message }
      }
    } catch (error) {
      console.error('Error logging in:', error)
      return { success: false, message: 'Error logging in' }
    }
  }

  const handleLogout = () => {
    Cookies.remove('gn_userToken')
    setUser(null)
    setSubscription(null)
  }

  const logout = async () => {
    setLoading(true)
    handleLogout()
    window.location.replace('/')
  }

  const refreshSubscription = async () => {
    if (user) {
      try {
        // First try to get subscription from existing user data
        const subscriptionExtracted = updateSubscriptionFromUserData(user)

        // Make API call to get the most up-to-date subscription data
        if (user.userId) {
          const result = await fetchSubscriptionStatus(user.userId)
          if (result) {
            // Return the updated subscription data so calling code can use it
            return result
          }
          return subscription
        }
        return subscriptionExtracted ? subscription : null
      } catch (error) {
        console.error('Error refreshing subscription data:', error)
        return subscription
      }
    }
    return null
  }

  // Function to immediately refresh subscription data
  const refreshSubscriptionImmediately = useCallback(async () => {
    if (user && user.userId) {
      console.log('Forcing immediate subscription refresh...')
      return await fetchSubscriptionStatus(user.userId)
    }
    return null
  }, [user])

  const providerValue = {
    user,
    loading,
    login,
    logout,
    subscription,
    refreshSubscription,
    refreshSubscriptionImmediately,
    hasActiveSubscription: subscription?.hasActiveSubscription || false
  }

  return <UserContext.Provider value={providerValue}>{children}</UserContext.Provider>
}
